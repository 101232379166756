import React, { Component } from "react";
import OrdersTable from "./../../components/ordersTable";

class AllOrders extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <h1>Orders Table</h1>
        <hr />
        <br />
        <OrdersTable />
      </React.Fragment>
    );
  }
}

export default AllOrders;
