import React, { Component } from "react";
import {
  selectAllUsers,
  selectLoadingStatus,
} from "../redux/users/users.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Table from "./tableComponent/table";
import TableUpdateButton from "./tableComponent/tableUpdateButton";
import TableDeleteButton from "./tableComponent/tableDeleteButton";
import { searchUser } from "./../redux/users/users.action";
import WithSpinner from "./withSpinner";

class CustomersTable extends Component {
  columns = [
    { title: "Username", path: "username" },
    { title: "Registration Date", path: "registrationDate" },
    { title: "Email", path: "email" },
    { title: "Role", path: "role" },
    {
      title: "Update User",
      buttons: (user) => (
        <TableUpdateButton user={user} label="Update" route="add-customer" />
      ),
      className: "tc-center",
    },
    {
      title: "Delete User",
      buttons: (user) => <TableDeleteButton user={user} label="Delete" />,
      className: "tc-center",
    },
  ];
  render() {
    const { users, searchUser, isLoading } = this.props;
    return isLoading ? (
      <WithSpinner />
    ) : (
      <React.Fragment>
        <Table
          elements={users}
          columns={this.columns}
          search={searchUser}
          title="All Zulucast Users"
          show="search"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  users: selectAllUsers,
  isLoading: selectLoadingStatus,
});

const mapDispatchToProps = (dispatch) => ({
  searchUser: (searchWord) => dispatch(searchUser(searchWord)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomersTable);
