import React, { Component } from "react";
import { selectVertualMovies } from "../redux/movies/movies.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Table from "./tableComponent/table";
import NotifyBtn from "./tableComponent/notifyBtn";
import { searchMovie } from "../redux/movies/movies.action";
import TableUpdateButton from "./tableComponent/tableUpdateButton";
import DeleteMovieBtn from "./tableComponent/deleteMovieBtn";
import { selectLoadingStatus } from "./../redux/movies/movies.selector";
import WithSpinner from "./withSpinner";

class MoviesTable extends Component {
  columns = [
    { title: "Title", path: "title" },
    { title: "Genre", path: "genre" },
    { title: "Actor", path: "actor" },
    // { title: "Price", path: "price" },
    { title: "Country", path: "countryName" },
    { title: "Language", path: "language" },
    { title: "Upload Date", path: "uploadDate" },
    {
      title: "Update Movie",
      buttons: (user) => (
        <TableUpdateButton
          user={user}
          label="Update"
          route="update-movie-details"
        />
      ),
      className: "tc-center",
    },
    // {
    //   title: "Update Movie Files",
    //   buttons: (user) => (
    //     <TableUpdateButton
    //       user={user}
    //       label="Update"
    //       route="update-movie-files"
    //     />
    //   ),
    //   className: "tc-center",
    // },
    {
      title: "Delete Movie",
      buttons: (movie) => <DeleteMovieBtn movie={movie} label="Delete" />,
      className: "tc-center",
    },
    {
      title: "Notify",
      buttons: (movie) => <NotifyBtn movie={movie} label="Notify" />,
      className: "tc-center",
    },
  ];

  render() {
    const { movies, searchMovie, isLoading } = this.props;
    return isLoading ? (
      <WithSpinner />
    ) : (
      <React.Fragment>
        <Table
          elements={movies}
          columns={this.columns}
          search={searchMovie}
          title="All Movies"
          show="search"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  movies: selectVertualMovies,
  isLoading: selectLoadingStatus,
});

const mapDispatchToProps = (dispatch) => ({
  searchMovie: (searchWord) => dispatch(searchMovie(searchWord)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MoviesTable);
