import React, { Component } from "react";

class Uptic extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-xl-12 col-xxl-12 d-flex">
            <div className="w-100">
              <div className="row">
                <div className="col-6 col-md-3">
                  <a className="no-decoration" href="/">
                    <div className="card bg-dark text-white">
                      <div className="card-body">
                        <h5 className="card-title mb-4 text-white-50">
                          Movies
                        </h5>
                        <h1 className="mt-1 mb-3 font-weight-bolder text-white">
                          205
                        </h1>
                        <div className="mb-1">
                          <span className="text-danger">
                            {" "}
                            <i className="mdi mdi-arrow-bottom-right"></i>{" "}
                            -3.65%{" "}
                          </span>
                          <span className="text-muted">Since last week</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-6 col-md-3">
                  <a className="no-decoration" href="/">
                    <div className="card bg-dark">
                      <div className="card-body">
                        <h5 className="card-title mb-4 text-white-50">Users</h5>
                        <h1 className="mt-1 mb-3 font-weight-bolder text-white">
                          205
                        </h1>
                        <div className="mb-1">
                          <span className="text-danger">
                            {" "}
                            <i className="mdi mdi-arrow-bottom-right"></i>{" "}
                            -3.65%{" "}
                          </span>
                          <span className="text-muted">Since last week</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-6 col-md-3">
                  <a className="no-decoration" href="/">
                    <div className="card bg-dark">
                      <div className="card-body">
                        <h5 className="card-title mb-4 text-white-50">
                          Orders
                        </h5>
                        <h1 className="mt-1 mb-3 font-weight-bolder text-white">
                          2,500
                        </h1>
                        <div className="mb-1">
                          <span className="text-success">
                            {" "}
                            <i className="mdi mdi-arrow-bottom-right"></i> 6.65%{" "}
                          </span>
                          <span className="text-muted">Since last week</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-6 col-md-3">
                  <a className="no-decoration" href="/">
                    <div className="card bg-dark">
                      <div className="card-body">
                        <h5 className="card-title mb-4 text-white-50">
                          Balance
                        </h5>
                        <h1 className="mt-1 mb-3 font-weight-bolder text-white">
                          $5000
                        </h1>
                        <div className="mb-1">
                          <span className="text-danger">
                            {" "}
                            <i className="mdi mdi-arrow-bottom-right"></i>{" "}
                            -2.25%{" "}
                          </span>
                          <span className="text-muted">Since last week</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12 col-xxl-12 d-flex">
            <div className="card flex-fill">
              <div className="card-header">
                <h5 className="card-title mb-0">Latest Users</h5>
              </div>
              <table className="table table-hover my-0">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="d-none d-xl-table-cell">
                      Registration Date
                    </th>
                    <th className="d-none d-xl-table-cell">Total Orders</th>
                    <th>Status</th>
                    <th className="d-none d-md-table-cell">Total Income</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>John Doe</td>
                    <td className="d-none d-xl-table-cell">01/01/2020</td>
                    <td className="d-none d-xl-table-cell">55</td>
                    <td>
                      <span className="badge bg-success">Active</span>
                    </td>
                    <td className="d-none d-md-table-cell">$50,000.00</td>
                  </tr>
                  <tr>
                    <td>Linda Doe</td>
                    <td className="d-none d-xl-table-cell">01/01/2021</td>
                    <td className="d-none d-xl-table-cell">2</td>
                    <td>
                      <span className="badge bg-danger">Inactive</span>
                    </td>
                    <td className="d-none d-md-table-cell">$50.00</td>
                  </tr>
                  <tr>
                    <td>Alfy Opare</td>
                    <td className="d-none d-xl-table-cell">01/01/2020</td>
                    <td className="d-none d-xl-table-cell">106</td>
                    <td>
                      <span className="badge bg-success">Active</span>
                    </td>
                    <td className="d-none d-md-table-cell">$11,000.00</td>
                  </tr>
                  <tr>
                    <td>Sadic Satsha</td>
                    <td className="d-none d-xl-table-cell">01/01/2020</td>
                    <td className="d-none d-xl-table-cell">0</td>
                    <td>
                      <span className="badge bg-danger">Inactive</span>
                    </td>
                    <td className="d-none d-md-table-cell">$0.00</td>
                  </tr>
                  <tr>
                    <td>Audrey Cage</td>
                    <td className="d-none d-xl-table-cell">01/01/2020</td>
                    <td className="d-none d-xl-table-cell">16</td>
                    <td>
                      <span className="badge bg-success">Active</span>
                    </td>
                    <td className="d-none d-md-table-cell">$300.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <br />
      </React.Fragment>
    );
  }
}

export default Uptic;
