import React from "react";
import Joi from "joi-browser";
//import { toast } from "react-toastify";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { NavLink } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";

import Form from "../../components/authForm";
import WithSpinner from "./../../components/withSpinner";
import {
  addGenreAsync,
  fetchGenresAsync,
} from "../../redux/moviesGenre/genres.action";
import { uploadMoviePicture } from "../../services/movieServices";
import { getGenre } from "../../services/genresServices";
import { selectLoadingStatus } from "../../redux/moviesGenre/genres.selector";
import { selectAllGenres } from "./../../redux/moviesGenre/genres.selector";

class AddGenre extends Form {
  state = {
    data: {
      name: "",
      description: "",
      genrePictureURL: "",
      positionOnDashboard: "",
    },
    position: [1, 2, 3, 4, 5, 6, 7, 8],
    positionTaken: [],
    picture: false,
    loading: false,
    error: {},
  };

  schema = {
    _id: Joi.string(),
    name: Joi.string().min(3).max(255).required().label("Name"),
    description: Joi.string().min(3).max(255).label("Description"),
    positionOnDashboard: Joi.number().min(0).label("Position"),
  };

  handlePictureSelect = (e) => {
    var picture = e.target.files[0];
    this.setState({ picture });
  };

  doSubmit = async () => {
    // if (!this.state.picture) {
    //   toast.info("Please set a picture for the genre");
    //   return;
    // }
    let pictureData;
    this.setState({ loading: true });

    if (this.state.picture) {
      const formData = new FormData();
      formData.append(
        "file",
        this.state.picture,
        `${this.state.data.name}.png`
      );
      const result = await uploadMoviePicture(formData);
      pictureData = result.data;
    }

    const { addGenreAsync } = this.props;
    addGenreAsync({
      ...this.state.data,
      ...(this.state.picture && { genrePictureURL: pictureData.fileLocation }),
    });
    this.setState({
      data: {
        name: "",
        description: "None",
        genrePictureURL: "",
        positionOnDashboard: 0,
      },
    });
    this.setState({ loading: false });
  };

  async componentDidMount() {
    document.title = "African Movies | Add Genre";
    const { match } = this.props;
    const id = match.params.id;

    const { genres, fetchGenresAsync } = this.props;
    fetchGenresAsync();

    let positionTaken = [];
    genres.map((genre) => positionTaken.push(genre.positionOnDashboard));
    this.setState({ positionTaken });

    if (id === "new") {
      this.setState({
        data: {
          name: "",
          description: "None",
          positionOnDashboard: 0,
        },
      });
    } else {
      const { data: genre } = await getGenre(id);
      this.setState({
        data: {
          _id: genre._id,
          name: genre.name,
          description: genre.description,
          positionOnDashboard: genre.positionOnDashboard,
        },
      });
    }
  }

  render() {
    const { isLoading, match } = this.props;
    const { position, positionTaken, loading } = this.state;
    const btnLabel = match.params.id === "new" ? "Add Genre" : "Update Genre";

    const genrePosition = position.filter(
      (pos) => !positionTaken.includes(pos)
    );

    return isLoading ? (
      <WithSpinner />
    ) : (
      <React.Fragment>
        {loading ? (
          <WithSpinner />
        ) : (
          <section className="auth">
            <div className="row auth-row no-gutter">
              <div
                className="col-md-12 bg-light-black"
                style={{ borderRadius: "8px" }}
              >
                <div className="container  d-flex h-100">
                  {match.params.id !== "new" && (
                    <NavLink
                      style={{
                        marginTop: "100px",
                        marginLeft: "30px",
                        color: "#fff",
                      }}
                      to="/all-genres"
                    >
                      <AiOutlineArrowLeft size="20" /> Back
                    </NavLink>
                  )}
                  <span className="m-auto">
                    <form
                      action="dashboard.html"
                      onSubmit={this.handleSubmit}
                      style={{ marginTop: "-80px" }}
                    >
                      {match.params.id !== "new" && (
                        <span>
                          Kindly fill the field you want to update then save
                        </span>
                      )}
                      {this.renderInput("text", "name", "Name", "Name")}
                      {this.renderInput(
                        "text",
                        "description",
                        "Description",
                        "Description"
                      )}

                      <div className="form-group">
                        <label className="form-label" htmlFor="">
                          Genre picture
                        </label>
                        <div className="input-group input-group-lg mb-3">
                          <input
                            type="file"
                            name="genrePictueURL"
                            onChange={this.handlePictureSelect}
                            className="form-control"
                            placeholder="genre picture"
                            aria-label="genre picture"
                            aria-describedby="basic-addon2"
                          />
                        </div>
                      </div>

                      {this.renderSelect(
                        "positionOnDashboard",
                        ["Click to Select", ...genrePosition],
                        "Position On Dashboard"
                      )}
                      <br />
                      {this.renderButton(`${btnLabel}`)}
                    </form>
                  </span>
                </div>
              </div>
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectLoadingStatus,
  genres: selectAllGenres,
});

const mapDispatchToProps = (dispatch) => ({
  addGenreAsync: (payload) => dispatch(addGenreAsync(payload)),
  fetchGenresAsync: () => dispatch(fetchGenresAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddGenre);
