import http from "./httpService";

const genresEndpoint = "/genre";

export function getGenres() {
  return http.get(genresEndpoint);
}

export function getGenre(id) {
  return http.get(genresEndpoint + "/" + id);
}

export function postGenre(payload) {
  if (payload._id) {
    const body = { ...payload };
    delete body._id;
    return http.put(genresEndpoint + "/update/" + payload._id, body);
  }
  return http.post(genresEndpoint, payload);
}

export function deleteGenre(id) {
  return http.delete(genresEndpoint + "/delete/" + id);
}
