import React, { Component } from "react";
import MoviesTable from "./../../components/moviesTable";

class AllMovies extends Component {
  render() {
    return (
      <React.Fragment>
        <h1>Movies Table.</h1>
        <hr />
        <br />
        <MoviesTable />
      </React.Fragment>
    );
  }
}

export default AllMovies;
