import React, { Component } from "react";
import GenresTable from "../../components/genresTable";

class AllGenres extends Component {
  render() {
    return (
      <React.Fragment>
        <h1>Genres Table</h1>
        <hr />
        <br />
        <GenresTable />
      </React.Fragment>
    );
  }
}

export default AllGenres;
