import movieActionTypes from "./movies.type";
import { toast } from "react-toastify";
import {
  getMovies,
  addOrder,
  getOrders,
  addLongevity,
  addMovie,
  deleteMovie,
} from "../../services/movieServices";

export const fetchMoviesStart = () => ({
  type: movieActionTypes.FETCH_MOVIES_START,
});

export const fetchMoviesSuccess = (movies) => ({
  type: movieActionTypes.FETCH_MOVIES_SUCCESS,
  payload: movies,
});

export const fetchMoviesFailure = () => ({
  type: movieActionTypes.FETCH_MOVIES_FAILURE,
});

export const fetchMoviesAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchMoviesStart());
      const { data } = await getMovies();
      dispatch(fetchMoviesSuccess(data));
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        dispatch(fetchMoviesFailure());
        toast.error(ex.response.data);
      }
    }
  };
};

export const fetchOrderStart = () => ({
  type: movieActionTypes.FETCH_ORDERS_START,
});

export const fetchOrderSuccess = (orders) => ({
  type: movieActionTypes.FETCH_ORDERS_SUCCESS,
  payload: orders,
});

export const fetchOrderFailure = () => ({
  type: movieActionTypes.FETCH_ORDERS_FAILURE,
});

export const fetchOrderAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchOrderStart());
      const { data } = await getOrders();
      dispatch(fetchOrderSuccess(data));
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        dispatch(fetchOrderFailure());
        toast.error(ex.response.data);
      }
    }
  };
};

export const addOrderStart = () => ({
  type: movieActionTypes.ADD_TO_ORDER_START,
});

export const addOrderSuccess = () => ({
  type: movieActionTypes.ADD_TO_ORDER_SUCCESS,
});

export const addOrderFailure = () => ({
  type: movieActionTypes.ADD_TO_ORDER_FAILURE,
});

export const addOrderAsync = (order) => {
  return async (dispatch) => {
    try {
      dispatch(addOrderStart());
      await addOrder(order);
      dispatch(addOrderSuccess());
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        dispatch(addOrderFailure());
        console.log(ex.response.data);
      }
    }
  };
};

export const addLongevityStart = () => ({
  type: movieActionTypes.ADD_MOVIE_LONGEVITY_START,
});

export const addLongevitySuccess = () => ({
  type: movieActionTypes.ADD_MOVIE_LONGEVITY_SUCCESS,
});

export const addLongevityFailure = () => ({
  type: movieActionTypes.ADD_MOVIE_LONGEVITY_FAILURE,
});

export const addLongevityAsync = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(addLongevityStart());
      await addLongevity(payload);
      dispatch(addLongevitySuccess());
      toast.success("Movie settings successfully saved");
    } catch (ex) {
      dispatch(addLongevityFailure());
      toast.error(ex.response.data);
    }
  };
};

export const searchMovie = (searchWord) => ({
  type: movieActionTypes.SEARCH_MOVIE,
  payload: searchWord,
});

export const addMovieStart = () => ({
  type: movieActionTypes.ADD_MOVIE_START,
});

export const addMovieSuccess = () => ({
  type: movieActionTypes.ADD_MOVIE_SUCCESS,
});

export const addMovieFailure = () => ({
  type: movieActionTypes.ADD_MOVIE_FAILURE,
});

export const addMovieAsync = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(addMovieStart());
      await addMovie(payload);
      dispatch(addMovieSuccess());
      toast.success("Movie successfully saved");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        dispatch(addMovieFailure());
        toast.error(ex.response.data);
      }
    }
  };
};

export const deleteMovieStart = () => ({
  type: movieActionTypes.DELETE_MOVIE_START,
});

export const deleteMovieSuccess = (movieId) => ({
  type: movieActionTypes.DELETE_MOVIE_SUCCESS,
  payload: movieId,
});

export const deleteMovieFailure = () => ({
  type: movieActionTypes.DELETE_MOVIE_FAILURE,
});

export const deleteMovieAsync = (movieId) => {
  return async (dispatch) => {
    try {
      dispatch(deleteMovieStart());
      await deleteMovie(movieId);
      dispatch(deleteMovieSuccess(movieId));
      toast.success("Movie successfully deleted");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        dispatch(deleteMovieFailure());
        toast.error(ex.response.data);
      }
    }
  };
};

export const searchOrder = (searchWord) => ({
  type: movieActionTypes.SEARCH_ORDER,
  payload: searchWord,
});
