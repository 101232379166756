import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/images/logo_test.png";
import { FiVideo, FiUser, FiFilm, FiUsers, FiSettings } from "react-icons/fi";
import { MdDashboard, MdPayment } from "react-icons/md";
import { HiDocumentReport } from "react-icons/hi";
import { BsPlus } from "react-icons/bs";
import { AiOutlineDotChart } from "react-icons/ai";
import { Collapse } from "react-bootstrap";
import { getCurrentUser } from "../services/authServices";

class SideBar extends Component {
  state = {
    toggleMovie: false,
    sidebar: "dashboard",
    toggleUser: false,
    toggleAdmin: false,
    toggleGenre: false,
    toggleSettings: false,
    toggleReports: false,
    isShown: false,
  };

  toggle = () => {
    this.setState({
      toggleMovie: !this.state.toggleMovie,
      toggleAdmin: false,
      toggleUser: false,
      toggleGenre: false,
      toggleSettings: false,
      toggleReports: false,
    });
  };

  toggle2 = () => {
    this.setState({
      toggleUser: !this.state.toggleUser,
      toggleAdmin: false,
      toggleMovie: false,
      toggleGenre: false,
      toggleSettings: false,
      toggleReports: false,
    });
  };
  toggle3 = () => {
    this.setState({
      toggleAdmin: !this.state.toggleAdmin,
      toggleMovie: false,
      toggleUser: false,
      toggleGenre: false,
      toggleSettings: false,
      toggleReports: false,
    });
  };
  toggle4 = () => {
    this.setState({
      toggleGenre: !this.state.toggleGenre,
      toggleAdmin: false,
      toggleUser: false,
      toggleMovie: false,
      toggleSettings: false,
      toggleReports: false,
    });
  };
  toggle5 = () => {
    this.setState({
      toggleSettings: !this.state.toggleSettings,
      toggleGenre: false,
      toggleAdmin: false,
      toggleUser: false,
      toggleMovie: false,
      toggleReports: false,
    });
  };
  toggle6 = () => {
    this.setState({
      toggleReports: !this.state.toggleReports,
      toggleGenre: false,
      toggleAdmin: false,
      toggleUser: false,
      toggleMovie: false,
      toggleSettings: false,
    });
  };

  activate = (name) => {
    this.setState({ sidebar: name });
  };

  render() {
    //const { toggleSidebar } = this.props;
    return (
      <React.Fragment>
        {/* <!-- Side bar --> */}

        <div className="sidebar-content js-simplebar">
          <a className="sidebar-brand" href="/">
            <span className="align-middle">
              {/* <img src={logo} width="200" alt="" /> */}
              <img src={logo} width={190} alt="no logo" height={40} />
            </span>
          </a>
          <ul className="sidebar-nav">
            {/* <!-- <li className="sidebar-header">
						Pages
					</li> --> */}
            <li
              className="sidebar-item side-nav-add-movies"
              //onClick={() => toggleSidebar()}
            >
              <NavLink to="add-movie-file/new">
                <div className="sidebar-link">
                  <a
                    style={{ backgroundColor: "#0F9ED1" }}
                    className="btn btn-primary btn-lg btn-block"
                  >
                    <i className="align-middle" data-feather="plus"></i>
                    <i className="align-middle" data-feather="sliders">
                      <BsPlus />
                    </i>
                    <span
                      className="align-middle"
                      style={{
                        color: "#fff",
                        marginLeft: "-8px",
                        textDecoration: "none",
                      }}
                    >
                      Add movies
                    </span>
                  </a>
                </div>
              </NavLink>
            </li>

            {/* <!-- <hr> --> */}

            <li
              className={
                this.state.sidebar === "dashboard"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
              //onClick={() => toggleSidebar()}
            >
              <NavLink
                to="/dashboard"
                className="sidebar-link"
                onClick={() => this.setState({ sidebar: "dashboard" })}
              >
                <i className="align-middle" data-feather="sliders">
                  <MdDashboard />
                </i>
                <span className="align-middle">Dashboard</span>
              </NavLink>
            </li>

            <li
              onClick={this.toggle}
              className={
                this.state.sidebar === "users"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a
                data-toggle="collapse"
                className="sidebar-link collapsed"
                aria-controls="example-collapse-text"
                aria-expanded={this.state.toggleMovie}
                onClick={() =>
                  this.setState({
                    sidebar: "users",
                  })
                }
              >
                <i className="align-middle" data-feather="video">
                  <FiVideo />
                </i>
                <span className="align-middle">Movies</span>
              </a>
              <Collapse in={this.state.toggleMovie}>
                <ul
                  id="movie-menu"
                  className={
                    this.state.toggleMovie
                      ? "sidebar-dropdown list-unstyled collapse show"
                      : "sidebar-dropdown list-unstyled collapse"
                  }
                  data-parent="#sidebar"
                >
                  <li
                    className="sidebar-item"
                    onClick={() => (window.location = "/add-movie-file/new")}
                  >
                    <a className="sidebar-link">Add new movie</a>
                  </li>
                  <li className="sidebar-item">
                    <NavLink to="/all-movies" className="sidebar-link">
                      All movies
                    </NavLink>
                  </li>
                  {/* {getCurrentUser().role === "super admin" && (
                    <li className="sidebar-item">
                      <NavLink to="/movies-sync" className="sidebar-link">
                        Movies Sync
                      </NavLink>
                    </li>
                  )} */}
                  <li className="sidebar-item">
                    <NavLink to="/settings" className="sidebar-link">
                      Longevity & Banner
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            <li
              onClick={this.toggle4}
              className={
                this.state.sidebar === "genres"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a
                data-toggle="collapse"
                className="sidebar-link collapsed"
                aria-controls="example-collapse-text"
                aria-expanded={this.state.toggleGenre}
                onClick={() => this.setState({ sidebar: "genres" })}
              >
                <i className="align-middle" data-feather="video">
                  <FiFilm />
                </i>
                <span className="align-middle">Genres</span>
              </a>
              <Collapse in={this.state.toggleGenre}>
                <ul
                  id="genre-menu"
                  className={
                    this.state.toggleGenre
                      ? "sidebar-dropdown list-unstyled collapse show"
                      : "sidebar-dropdown list-unstyled collapse"
                  }
                  data-parent="#sidebar"
                >
                  <li
                    className="sidebar-item"
                    onClick={() => (window.location = "/add-genre/new")}
                  >
                    <a className="sidebar-link">Add new genre</a>
                  </li>
                  <li className="sidebar-item">
                    <NavLink className="sidebar-link" to="/all-genres">
                      All genres
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              onClick={this.toggle5}
              className={
                this.state.sidebar === "settings"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a
                data-toggle="collapse"
                className="sidebar-link collapsed"
                aria-controls="example-collapse-text"
                aria-expanded={this.state.toggleSettings}
                onClick={() => this.setState({ sidebar: "settings" })}
              >
                <i className="align-middle" data-feather="video">
                  <FiSettings />
                </i>
                <span className="align-middle">Settings</span>
              </a>
              <Collapse in={this.state.toggleSettings}>
                <ul
                  id="settings-menu"
                  className={
                    this.state.toggleSettings
                      ? "sidebar-dropdown list-unstyled collapse show"
                      : "sidebar-dropdown list-unstyled collapse"
                  }
                  data-parent="#sidebar"
                >
                  <li
                    className="sidebar-item"
                    onClick={() => (window.location = "/add-country/new")}
                  >
                    <a className="sidebar-link">Add Country</a>
                  </li>
                  <li className="sidebar-item">
                    <NavLink className="sidebar-link" to="/all-countries">
                      All countries
                    </NavLink>
                  </li>
                  <li
                    className="sidebar-item"
                    onClick={() => (window.location = "/add-language/new")}
                  >
                    <a className="sidebar-link">Add Language</a>
                  </li>
                  <li className="sidebar-item">
                    <NavLink className="sidebar-link" to="/all-languages">
                      All Languages
                    </NavLink>
                  </li>
                  <hr />
                  <li
                    className="sidebar-item"
                    onClick={() => (window.location = "/webqrcode")}
                  >
                    <a className="sidebar-link">Web Qrcode</a>
                  </li>
                  <li
                    className="sidebar-item"
                    onClick={() => (window.location = "/appqrcode")}
                  >
                    <a className="sidebar-link">App Qrcode</a>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li
              onClick={this.toggle3}
              className={
                this.state.sidebar === "admins"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a
                data-toggle="collapse"
                className="sidebar-link collapsed"
                aria-controls="example-collapse-text"
                aria-expanded={this.state.toggleAdmin}
                onClick={() => this.setState({ sidebar: "admins" })}
              >
                <i className="align-middle" data-feather="video">
                  <FiUser />
                </i>
                <span className="align-middle">Admins</span>
              </a>
              <Collapse in={this.state.toggleAdmin}>
                <ul
                  id="admin-menu"
                  className={
                    this.state.toggleAdmin
                      ? "sidebar-dropdown list-unstyled collapse show"
                      : "sidebar-dropdown list-unstyled collapse"
                  }
                  data-parent="#sidebar"
                >
                  <li
                    className="sidebar-item"
                    onClick={() => (window.location = "/add-user/new")}
                  >
                    <a className="sidebar-link">Add new admin</a>
                  </li>
                  <li className="sidebar-item">
                    <NavLink className="sidebar-link" to="/users">
                      All admins
                    </NavLink>
                  </li>
                  {getCurrentUser().role === "super admin" && (
                    <li className="sidebar-item">
                      <NavLink className="sidebar-link" to="/admin-access">
                        Admin access level
                      </NavLink>
                    </li>
                  )}
                  {getCurrentUser().role === "super admin" && (
                    <li className="sidebar-item">
                      <NavLink
                        className="sidebar-link"
                        to="/super-admin-access"
                      >
                        Super admin access level
                      </NavLink>
                    </li>
                  )}
                </ul>
              </Collapse>
            </li>
            <li
              onClick={this.toggle2}
              className={
                this.state.sidebar === "customers"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
            >
              <a
                data-toggle="collapse"
                className="sidebar-link collapsed"
                aria-controls="example-collapse-text"
                aria-expanded={this.state.toggleUser}
                onClick={() => this.setState({ sidebar: "customers" })}
              >
                <i className="align-middle" data-feather="video">
                  <FiUsers />
                </i>
                <span className="align-middle">Customers</span>
              </a>
              <Collapse in={this.state.toggleUser}>
                <ul
                  id="customer-menu"
                  className={
                    this.state.toggleUser
                      ? "sidebar-dropdown list-unstyled collapse show"
                      : "sidebar-dropdown list-unstyled collapse"
                  }
                  data-parent="#sidebar"
                >
                  <li
                    className="sidebar-item"
                    onClick={() => (window.location = "/add-customer/new")}
                  >
                    <a className="sidebar-link">Add new customer</a>
                  </li>
                  <li className="sidebar-item">
                    <NavLink className="sidebar-link" to="/customers">
                      All customers
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            {getCurrentUser().role === "super admin" && (
              <li
                onClick={this.toggle6}
                className={
                  this.state.sidebar === "reports"
                    ? "sidebar-item active"
                    : "sidebar-item"
                }
              >
                <a
                  data-toggle="collapse"
                  className="sidebar-link collapsed"
                  aria-controls="example-collapse-text"
                  aria-expanded={this.state.toggleReports}
                  onClick={() => this.setState({ sidebar: "reports" })}
                >
                  <i className="align-middle" data-feather="video">
                    <HiDocumentReport />
                  </i>
                  <span className="align-middle">Reports</span>
                </a>
                <Collapse in={this.state.toggleReports}>
                  <ul
                    id="reports-menu"
                    className={
                      this.state.toggleReports
                        ? "sidebar-dropdown list-unstyled collapse show"
                        : "sidebar-dropdown list-unstyled collapse"
                    }
                    data-parent="#sidebar"
                  >
                    <li className="sidebar-item">
                      <NavLink className="sidebar-link" to="/upload-reports">
                        Movie upload reports
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            )}

            <li
              className={
                this.state.sidebar === "payment"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
              // onClick={() => toggleSidebar()}
            >
              <NavLink
                to="/all-orders"
                className="sidebar-link"
                onClick={() => this.setState({ sidebar: "payment" })}
              >
                <i className="align-middle" data-feather="sliders">
                  <MdPayment />
                </i>
                <span className="align-middle">Payment</span>
              </NavLink>
            </li>
            <li
              className={
                this.state.sidebar === "statistics"
                  ? "sidebar-item active"
                  : "sidebar-item"
              }
              // onClick={() => toggleSidebar()}
            >
              <NavLink
                to="/statistics"
                className="sidebar-link"
                onClick={() => this.setState({ sidebar: "statistics" })}
              >
                <i className="align-middle" data-feather="sliders">
                  <AiOutlineDotChart />
                </i>
                <span className="align-middle">Statistics</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default SideBar;
