const genresActionType = {
  FETCH_GENRE_START: "FETCH_GENRE_START",
  FETCH_GENRE_SUCCESS: "FETCH_GENRE_SUCCESS",
  FETCH_GENRE_FAILURE: "FETCH_GENRE_FAILURE",
  ADD_GENRE_START: "ADD_GENRE_START",
  ADD_GENRE_SUCCESS: "ADD_GENRE_SUCCESS",
  ADD_GENRE_FAILURE: "ADD_GENRE_FAILURE",
  DELETE_GENRE_START: "DELETE_GENRE_START",
  DELETE_GENRE_SUCCESS: "DELETE_GENRE_SUCCESS",
  DELETE_GENRE_FAILURE: "DELETE_GENRE_FAILURE",
};

export default genresActionType;
