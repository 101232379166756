export const searchMovie = (allMovies, filterWord) => {
  return allMovies.filter((movie) =>
    movie.title.toLowerCase().includes(filterWord.toLowerCase())
  );
};

export const searchOrder = (orders, filterWord) => {
  return orders.filter((order) =>
    order.username.toLowerCase().startsWith(filterWord.toLowerCase())
  );
};

export const deleteMovie = (allMovies, movieId) => {
  return allMovies.filter((movie) => movie._id !== movieId);
};
