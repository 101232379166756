import React, { Component } from "react";
import axios from "axios";
import { selectVertualMovies } from "../redux/movies/movies.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectLoadingStatus } from "./../redux/movies/movies.selector";
import Table from "./tableComponent/table";
import AddToAF from "./tableComponent/adToAf";

class MoviesSyncTable extends Component {
  state = {
    zulucastmovies: [],
  };
  columns = [
    { title: "Title", path: "title" },
    { title: "Genre", path: "genre" },
    { title: "Actor", path: "actor" },
    { title: "Language", path: "language" },
    {
      title: "Add To African Movies",
      buttons: (movie) => (
        <AddToAF movie={movie} label="Add To African Movies" />
      ),
      className: "tc-center",
    },
  ];

  searchMovie = (filterWord) => {
    const filtered = this.state.zulucastmovies.filter((movie) =>
      movie.title.toLowerCase().startsWith(filterWord.toLowerCase())
    );
    this.setState({ zulucastmovies: filtered });
  };

  async componentDidMount() {
    const { data } = await axios.get(process.env.REACT_APP_API_ZC_MOVIES);
    this.setState({ zulucastmovies: data });
  }
  render() {
    const zuluTitles = [];
    const { movies: zulumovies } = this.props;
    zulumovies.map((v) => zuluTitles.push(v.videoId));

    const syncMovies = this.state.zulucastmovies.filter(
      (a) => !zuluTitles.includes(a.videoId)
    );

    return (
      <React.Fragment>
        <Table
          elements={syncMovies}
          columns={this.columns}
          search={this.searchMovie}
          title="All Zulucast movies Unavailable in African Movies"
          show="af_search"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  movies: selectVertualMovies,
  isLoading: selectLoadingStatus,
});

export default connect(mapStateToProps)(MoviesSyncTable);
