import { createSelector } from "reselect";

const selectMovies = (state) => state.movies;

export const selectLoadingStatus = createSelector(
  [selectMovies],
  (movies) => movies.isLoading
);

export const selectVertualMovies = createSelector(
  [selectMovies],
  (movies) => movies.vertualMovies
);

export const selectAllMovies = createSelector(
  [selectMovies],
  (movies) => movies.allMovies
);

export const selectOrders = createSelector(
  [selectMovies],
  (movies) => movies.vertualOrders
);

export const selectOrderTotalPrice = createSelector([selectOrders], (orders) =>
  orders.reduce((price, order) => price + order.price, 0)
);
