import React, { Component } from "react";
import { selectUsers } from "../redux/users/users.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Table from "./tableComponent/table";
import TableUpdateButton from "./tableComponent/tableUpdateButton";
import TableDeleteButton from "./tableComponent/tableDeleteButton";

class UsersTable extends Component {
  columns = [
    { title: "Username", path: "username" },
    { title: "Registration Date", path: "registrationDate" },
    { title: "Email", path: "email" },
    { title: "Role", path: "role" },
    {
      title: "Update User",
      buttons: (user) => (
        <TableUpdateButton user={user} label="Update" route="add-user" />
      ),
      className: "tc-center",
    },
    {
      title: "Delete User",
      buttons: (user) => <TableDeleteButton user={user} label="Delete" />,
      className: "tc-center",
    },
  ];

  render() {
    const { users } = this.props;
    return (
      <React.Fragment>
        <Table elements={users} columns={this.columns} title="Admin Users" />
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  users: selectUsers,
});

export default connect(mapStateToProps)(UsersTable);
