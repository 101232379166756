const usersActionTypes = {
  USERS_FETCH_START: "USERS_FETCH_START",
  USERS_FETCH_SUCCESS: "USERS_FETCH_SUCCESS",
  USERS_FETCH_FAILURE: "USERS_FETCH_FAILURE",
  ALL_USERS_FETCH_START: "ALL_USERS_FETCH_START",
  ALL_USERS_FETCH_SUCCESS: "ALL_USERS_FETCH_SUCCESS",
  ALL_USERS_FETCH_FAILURE: "ALL_USERS_FETCH_FAILURE",
  LOGIN_USER_START: "LOGIN_USER_START",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",
  LOGIN_ADMIN_USER_START: "LOGIN_ADMIN_USER_START",
  LOGIN_ADMIN_USER_SUCCESS: "LOGIN_ADMIN_USER_SUCCESS",
  LOGIN_ADMIN_USER_FAILURE: "LOGIN_ADMIN_USER_FAILURE",
  REGISTER_USER_START: "REGISTER_USER_START",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILURE: "REGISTER_USER_FAILURE",
  ADD_ADMIN_START: "ADD_ADMIN_START",
  ADD_ADMIN_SUCCESS: "ADD_ADMIN_SUCCESS",
  ADD_ADMIN_FAILURE: "ADD_ADMIN_FAILURE",
  PASSWORD_RESET_START: "PASSWORD_RESET_START",
  PASSWORD_RESET_SUCCESS: "PASSWORD_RESET_SUCCESS",
  PASSWORD_RESET_FAILURE: "PASSWORD_RESET_FAILURE",
  SEARCH_USER: "SEARCH_USER",
  DELETE_USER_START: "DELETE_USER_START",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "DELETE_USER_FAILURE",
};

export default usersActionTypes;
