import React, { Component } from "react";
import { selectAllGenres } from "../redux/moviesGenre/genres.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Table from "./tableComponent/table";
import GenreDeleteBtn from "./tableComponent/genreDeleteBtn";
import { selectLoadingStatus } from "./../redux/moviesGenre/genres.selector";
import WithSpinner from "./withSpinner";
import TableUpdateButton from "./tableComponent/tableUpdateButton";

class GenresTable extends Component {
  columns = [
    { title: "Name", path: "name" },
    { title: "Position On Home Page", path: "positionOnDashboard" },
    {
      title: "Update Genre",
      buttons: (user) => (
        <TableUpdateButton user={user} label="Update" route="add-genre" />
      ),
      className: "tc-center",
    },
    {
      title: "Delete Genre",
      buttons: (genre) => <GenreDeleteBtn genre={genre} label="Delete" />,
      className: "tc-center",
    },
  ];
  render() {
    const { genres, isLoading } = this.props;
    return isLoading ? (
      <WithSpinner />
    ) : (
      <React.Fragment>
        <Table elements={genres} columns={this.columns} title="All Genres" />
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  genres: selectAllGenres,
  isLoading: selectLoadingStatus,
});

export default connect(mapStateToProps)(GenresTable);
