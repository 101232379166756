import React, { useState } from "react";
import QRCode from "qrcode";

export default function WebQrcode() {
  const url = "africanmovies.com";
  const [width, setWidth] = useState();
  const [qr, setQr] = useState("");
  const GenerateQRCode = () => {
    QRCode.toDataURL(
      url,
      {
        width,
        margin: 2,
        color: {
          // dark: '#335383FF',
          // light: '#EEEEEEFF'
        },
      },
      (err, url) => {
        if (err) return console.error(err);
        console.log(url);
        setQr(url);
      }
    );
  };
  return (
    <React.Fragment>
      <h1>Web Qr Code</h1>
      <br />
      {qr && (
        <button onClick={() => (window.location = "/webqrcode")}>Erase</button>
      )}
      <br />
      <br />
      {!qr && (
        <button variant="contained" onClick={GenerateQRCode}>
          Generate
        </button>
      )}
      {!qr && (
        <input
          type="text"
          style={{ marginLeft: "20px" }}
          placeholder="qr code size"
          value={width}
          onChange={(e) => setWidth(e.target.value)}
        />
      )}
      {qr && (
        <>
          <img alt="no qr" src={qr} />
        </>
      )}
    </React.Fragment>
  );
}
