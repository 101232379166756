import React, { Component } from "react";
import { selectAllCountries } from "../redux/country/country.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Table from "./tableComponent/table";
import CountryDeleteBtn from "./tableComponent/countryDeleteBtn";
import { selectLoadingStatus } from "./../redux/country/country.selector";
import WithSpinner from "./withSpinner";
import TableUpdateButton from "./tableComponent/tableUpdateButton";

class CountriesTable extends Component {
  columns = [
    { title: "Country name", path: "countryName" },
    { title: "Added By", path: "addedBy" },
    { title: "Added On", path: "addedOn" },
    {
      title: "Update Country",
      buttons: (user) => (
        <TableUpdateButton user={user} label="Update" route="add-country" />
      ),
      className: "tc-center",
    },
    {
      title: "Delete Country",
      buttons: (country) => (
        <CountryDeleteBtn country={country} label="Delete" />
      ),
      className: "tc-center",
    },
  ];
  render() {
    const { countries, isLoading } = this.props;
    return isLoading ? (
      <WithSpinner />
    ) : (
      <React.Fragment>
        <Table
          elements={countries}
          columns={this.columns}
          title="All countries"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  countries: selectAllCountries,
  isLoading: selectLoadingStatus,
});

export default connect(mapStateToProps)(CountriesTable);
