import usersActionTypes from "./users.types";
import { getUsers, getAllUsers } from "../../services/usersService";
import { toast } from "react-toastify";
import {
  login,
  register,
  loginAdmin,
  resetPassword,
} from "../../services/authServices";
import { addAdmin, deleteUser } from "../../services/usersService";

export const fetchUsersStart = () => ({
  type: usersActionTypes.USERS_FETCH_START,
});

export const fetchUsersSuccess = (users) => ({
  type: usersActionTypes.USERS_FETCH_SUCCESS,
  payload: users,
});

export const fetchUsersFailure = () => ({
  type: usersActionTypes.USERS_FETCH_FAILURE,
});

export const fetchUsersAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchUsersStart());
      const { data: users } = await getUsers();
      dispatch(fetchUsersSuccess(users));
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        dispatch(fetchUsersFailure());
        //toast.error(ex.response.data);
      }
    }
  };
};

export const fetchAllUsersStart = () => ({
  type: usersActionTypes.ALL_USERS_FETCH_START,
});

export const fetchAllUsersSuccess = (users) => ({
  type: usersActionTypes.ALL_USERS_FETCH_SUCCESS,
  payload: users,
});

export const fetchAllUsersFailure = () => ({
  type: usersActionTypes.ALL_USERS_FETCH_FAILURE,
});

export const fetchAllUsersAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchAllUsersStart());
      const { data: users } = await getAllUsers();
      dispatch(fetchAllUsersSuccess(users));
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        dispatch(fetchAllUsersFailure());
        //toast.error(ex.response.data);
      }
    }
  };
};

export const loginUserStart = () => ({
  type: usersActionTypes.LOGIN_USER_START,
});

export const loginUserSuccess = () => ({
  type: usersActionTypes.LOGIN_USER_SUCCESS,
});

export const loginUserFailure = () => ({
  type: usersActionTypes.LOGIN_USER_FAILURE,
});

// export const userPasswordResetAsync = (user_details) => {
//   return async (dispatch) => {
//     try {
//       dispatch(addDataStart());
//       await changePassword(user_details);
//       dispatch(addDataSuccess());
//       toast("Password successfully reset.");
//       window.location = "/dashboard";
//     } catch (ex) {
//       if (ex.response && ex.response.status === 404) {
//         dispatch(addDataFailure());
//         toast.error(ex.response.data);
//       }
//     }
//   };
// };

export const loginUserAsync = (user_details) => {
  return async (dispatch) => {
    try {
      dispatch(loginUserStart());
      const { data: token } = await login(user_details);
      dispatch(loginUserSuccess());
      localStorage.setItem("token", token);
      toast.success("Successfully logged In, Redirecting...");
      if ("from" in localStorage) {
        window.location = "/cart";
        localStorage.removeItem("from");
      } else window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        dispatch(loginUserFailure());
        toast.error(ex.response.data);
      }
    }
  };
};

export const loginAdminStart = () => ({
  type: usersActionTypes.LOGIN_ADMIN_USER_START,
});

export const loginAdminSuccess = () => ({
  type: usersActionTypes.LOGIN_ADMIN_USER_SUCCESS,
});

export const loginAdminFailure = () => ({
  type: usersActionTypes.LOGIN_ADMIN_USER_FAILURE,
});

export const loginAdminAsync = (user_details) => {
  return async (dispatch) => {
    try {
      dispatch(loginAdminStart());
      const { data: token } = await loginAdmin(user_details);
      dispatch(loginAdminSuccess());
      localStorage.setItem("token", token);
      toast.success("Successfully logged In, Redirecting...");
      // if ("from" in localStorage) {
      //   window.location = "/cart";
      //   localStorage.removeItem("from");
      // } else
      window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        dispatch(loginAdminFailure());
        alert(ex.response.data);
        toast.error(ex.response.data);
      }
    }
  };
};

export const registerUserStart = () => ({
  type: usersActionTypes.REGISTER_USER_START,
});

export const registerUserSuccess = () => ({
  type: usersActionTypes.REGISTER_USER_SUCCESS,
});

export const registerUserFailure = () => ({
  type: usersActionTypes.REGISTER_USER_FAILURE,
});

export const registerUserAsync = (user_details) => {
  return async (dispatch) => {
    try {
      dispatch(registerUserStart());
      await register(user_details);
      dispatch(registerUserSuccess());
      // localStorage.setItem("token", token);
      // localStorage.removeItem("zulu_mail");
      toast.success("Customer successfully saved");
      setTimeout(() => (window.location = "/add-customer/new"), 3000);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        dispatch(registerUserFailure());
        alert(ex.response.data);
        toast.error(ex.response.data);
        setTimeout(() => (window.location = "/add-customer/new"), 3000);
      }
    }
  };
};

export const addAdminStart = () => ({
  type: usersActionTypes.ADD_ADMIN_START,
});

export const addAdminSuccess = () => ({
  type: usersActionTypes.ADD_ADMIN_SUCCESS,
});

export const addAdminFailure = () => ({
  type: usersActionTypes.ADD_ADMIN_FAILURE,
});

export const addAdminAsync = (user_details) => {
  return async (dispatch) => {
    try {
      dispatch(addAdminStart());
      await addAdmin(user_details);
      dispatch(addAdminSuccess());
      // localStorage.setItem("token", token);
      // localStorage.removeItem("zulu_mail");
      toast.success("User successfully added");
      setTimeout(() => (window.location = "/"), 3000);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        dispatch(addAdminFailure());
        alert(ex.response.data);
        toast.error(ex.response.data);
        setTimeout(() => (window.location = "/"), 3000);
      }
    }
  };
};

export const passwordResetStart = () => ({
  type: usersActionTypes.PASSWORD_RESET_START,
});

export const passwordResetSuccess = () => ({
  type: usersActionTypes.PASSWORD_RESET_SUCCESS,
});

export const passwordResetFailure = () => ({
  type: usersActionTypes.PASSWORD_RESET_FAILURE,
});

export const passwordResetAsync = (user_details) => {
  return async (dispatch) => {
    try {
      dispatch(passwordResetStart());
      await resetPassword(user_details);
      dispatch(passwordResetSuccess());
      toast.success("Successfull Reset, Redirecting...");
      localStorage.removeItem("token");
      window.location = "/login";
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        dispatch(passwordResetFailure());
        //toast.error(ex.response.data);
        alert(ex.response.data);
      }
    }
  };
};

export const deleteUserStart = () => ({
  type: usersActionTypes.DELETE_USER_START,
});

export const deleteUserSuccess = (userId) => ({
  type: usersActionTypes.DELETE_USER_SUCCESS,
  payload: userId,
});

export const deleteUserFailure = () => ({
  type: usersActionTypes.DELETE_USER_FAILURE,
});

export const deleteUserAsync = (userId) => {
  return async (dispatch) => {
    try {
      dispatch(deleteUserStart());
      await deleteUser(userId);
      dispatch(deleteUserSuccess(userId));
      toast.success("User deleted successfully");
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        dispatch(deleteUserFailure());
        toast.error(ex.response.data);
      }
    }
  };
};

export const searchUser = (searchWord) => ({
  type: usersActionTypes.SEARCH_USER,
  payload: searchWord,
});
