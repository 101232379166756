import http from "./httpService";

const usersEndpoint = "/auth";
const accessEnpoint = "/admin";
const superEnpoint = "/super-admin";

export function getUsers() {
  return http.get(usersEndpoint + "/admins");
}

export function getUser(id) {
  return http.get(usersEndpoint + "/one-user/" + id);
}

export function getAllUsers() {
  return http.get(usersEndpoint);
}

export function getAdminAccess() {
  return http.get(accessEnpoint);
}

export function postAdminAccess(payload) {
  return http.post(accessEnpoint, payload);
}

export function getSuperAdminAccess() {
  return http.get(superEnpoint);
}

export function postSuperAdminAccess(payload) {
  return http.post(superEnpoint, payload);
}

export function addAdmin(adminDetails) {
  if (adminDetails._id) {
    const body = { ...adminDetails };
    delete body._id;
    return http.put(usersEndpoint + "/admin-update/" + adminDetails._id, body);
  }
  return http.post(usersEndpoint + "/register-admin", adminDetails);
}

export function deleteUser(id) {
  return http.delete(usersEndpoint + "/delete/" + id);
}
