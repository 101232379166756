import React from "react";
import Joi from "joi-browser";
import Form from "../../components/authForm";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import WithSpinner from "./../../components/withSpinner";
import { passwordResetAsync } from "../../redux/users/users.action";
import { selectLoadingStatus } from "../../redux/users/users.selector";

class ResetPassword extends Form {
  state = {
    data: {
      oldPassword: "",
      password: "",
      password_confirm: "",
    },
    error: {},
  };

  schema = {
    oldPassword: Joi.string().min(8).max(20).required().label("Old Password"),
    password: Joi.string().min(8).max(20).required().label("New Password"),
    password_confirm: Joi.ref("password"),
  };

  doSubmit = async () => {
    const { passwordResetAsync } = this.props;
    passwordResetAsync({
      oldPassword: this.state.data.oldPassword,
      password: this.state.data.password,
      password_confirm: this.state.data.password_confirm,
    });
  };

  componentDidMount() {
    document.title = "African Movies | Reset Password";
  }

  render() {
    const { isLoading } = this.props;
    return isLoading ? (
      <WithSpinner />
    ) : (
      <React.Fragment>
        <section className="auth">
          <div className="row auth-row no-gutter">
            <div className="col-md-12 bg-light-black">
              <div className="container  d-flex h-100">
                <span className="m-auto">
                  <form
                    action="dashboard.html"
                    onSubmit={this.handleSubmit}
                    style={{ marginTop: "-80px", maxWidth: "280px" }}
                  >
                    <span style={{ textAlign: "center" }}>
                      After password is Changed, you'll be logged out and
                      directed to login with your new credentials.
                    </span>
                    {this.renderInput(
                      "password",
                      "oldPassword",
                      "Old Password",
                      "Old Password",
                      "fa fa-key"
                    )}
                    {this.renderInput(
                      "password",
                      "password",
                      "New Password",
                      "New Password",
                      "fa fa-key"
                    )}
                    {this.renderInput(
                      "password",
                      "password_confirm",
                      "Confirm Password",
                      "Confirm Password",
                      "fa fa-key"
                    )}

                    {this.renderButton("Reset Password")}
                  </form>
                </span>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectLoadingStatus,
});

const mapDispatchToProps = (dispatch) => ({
  passwordResetAsync: (user) => dispatch(passwordResetAsync(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
