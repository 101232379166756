import React from "react";
import { Switch, Route } from "react-router-dom";
import WrapperPages from "./pages/wrapperPage";
import Login from "./pages/login";
import { getCurrentUser } from "./services/authServices";

import "react-toastify/dist/ReactToastify.css";

const App = () => {
  return (
    <React.Fragment>
      <Switch>
        {getCurrentUser() && <Route path="/" component={WrapperPages} />}
        {!getCurrentUser() && <Route path="/" component={Login} />}
        {/* <ProtectedRoute
          path="/"
          currentUser={getCurrentUser()}
          render={(props) => <Login {...props} />}
        /> */}
        {/* <Redirect from="/" to="/login" /> */}
      </Switch>
    </React.Fragment>
  );
};

export default App;
