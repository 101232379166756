import React from "react";
import Joi from "joi-browser";
import Form from "../../components/authForm";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import MovieSpinner from "./../../components/movieSpinner";

import { NavLink } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import {
  fetchCountryAsync,
  fetchLanguageAsync,
} from "../../redux/country/country.action";
import { uploadMoviePicture } from "../../services/movieServices";

import {
  selectAllCountries,
  selectAllLanguages,
} from "./../../redux/country/country.selector";
import {
  selectLoadingStatus,
  selectAllMovies,
} from "../../redux/movies/movies.selector";
import { selectAllGenres } from "./../../redux/moviesGenre/genres.selector";
import { addMovieAsync } from "../../redux/movies/movies.action";
import { fetchGenresAsync } from "../../redux/moviesGenre/genres.action";
import { toast } from "react-toastify";

class UpdateMovie extends Form {
  state = {
    data: {
      title: "",
      price: "",
      rating: "",
      description: "",
      actor: [],
      name: "",
      duration: "",
      isBanner: "",
      countryName: "",
      language: "",
      releaseType: "",
      releaseYear: "",
      moviePictureURL: "",
      movieVideoURL: "",
      movieTrailerURL: "",
      movieBannerPictureURL: "",
    },
    picture: false,
    bannerPicture: false,
    trailerVideo: false,
    movieVideo: false,
    actors: [],
    allGenres: [],
    allCountries: [],
    languages: [],
    isBanner: ["No, don't set as banner", "Yes, set as banner"],
    type: ["New Releases", "Trending", "Old Movie"],
    isLoading: false,
    trailerCompleted: 0,
    videoCompleted: 0,
    error: {},
  };

  schema = {
    title: Joi.string().min(3).max(255).required().label("Title"),
    price: Joi.number().min(0).required().label("Price"),
    rating: Joi.number().min(6).max(18).label("Rating"),
    description: Joi.string()
      .min(3)
      .max(100000)
      .required()
      .label("Description"),
    actor: Joi.array().items(Joi.string()),
    name: Joi.string().required().label("Genre"),
    duration: Joi.number().min(1).label("Duration in minutes"),
    isBanner: Joi.string().label("Is Banner"),
    releaseType: Joi.string().max(255).label("Release Type"),
    releaseYear: Joi.string().max(30).label("Release Year"),
    moviePictureURL: Joi.string().required().label("Movie Picture URL"),
    movieTrailerURL: Joi.string().required().label("Movie Trailer URL"),
    movieVideoURL: Joi.string().required().label("Movie Video URL"),
    countryName: Joi.string().min(3).max(255),
    language: Joi.string().min(3).max(255),
  };

  doSubmit = async () => {
    const { addMovieAsync, match } = this.props;

    const {
      title,
      price,
      rating,
      description,
      actor,
      name,
      duration,
      isBanner,
      countryName,
      language,
      releaseType,
      releaseYear,
      moviePictureURL,
      movieVideoURL,
      movieTrailerURL,
    } = this.state.data;

    if (isBanner === "Yes, set as banner" && !this.state.bannerPicture) {
      toast.info("Please select a banner picture.");
      return;
    }

    if (this.state.actors.length <= 0) {
      toast.info("Enter a least one actor name to proceed.");
      return;
    }

    this.setState({ isLoading: true });

    const bannerFormData = new FormData();
    if (this.state.bannerPicture) {
      bannerFormData.append(
        "file",
        this.state.bannerPicture,
        `${this.state.data.title}-banner.png`
      );
    }

    let bannerPictureData;
    if (this.state.bannerPicture) {
      const { data } = await uploadMoviePicture(bannerFormData);
      bannerPictureData = data;
    }

    addMovieAsync({
      _id: match.params.id,
      title,
      price,
      rating,
      description,
      actor,
      genre: name,
      duration,
      countryName,
      language,
      isBanner: isBanner === "Yes, set as banner" ? true : false,
      releaseType,
      releaseYear,
      movieVideoURL,
      movieTrailerURL,
      moviePictureURL,
      movieBannerPictureURL: bannerPictureData?.fileLocation,
    });

    this.setState({
      data: {
        title: "",
        price: "",
        rating: "",
        description: "",
        actor: "",
        name: "",
        duration: "",
        countryName: "",
        language: "",
        isBanner: "",
        releaseType: "",
        releaseYear: "",
        moviePictureURL: "",
        movieVideoURL: "",
        movieTrailerURL: "",
        movieBannerPictureURL: "",
      },
      picture: false,
      movieVideo: false,
      trailerVideo: false,
      bannerPicture: false,
      trailerCompleted: 0,
      videoCompleted: 0,
    });
    this.setState({ isLoading: false });
    toast.success("Movie successfully updated...");
  };

  handleBannerPictureSelect = (e) => {
    var bannerPicture = e.target.files[0];
    this.setState({ bannerPicture });
  };

  handleKeyDown = (e) => {
    if (e.keyCode !== 39) return;
    const value = e.target.value;
    if (!value.trim()) return;
    this.setState({ actors: [...this.state.actors, value] });
    this.setState({
      data: {
        ...this.state.data,
        actor: [...this.state.actors, value],
      },
    });
    e.target.value = "";
  };

  removeActor = (index) => {
    const newActors = this.state.actors.filter((actor, i) => i !== index);
    this.setState({ actors: newActors });
    this.setState({
      data: {
        ...this.state.data,
        actor: newActors,
      },
    });
  };

  async componentDidMount() {
    document.title = "African Movies | update Movie";
    const {
      allGenres,
      fetchGenresAsync,
      match,
      movies,
      allCountries,
      languages,
      fetchCountryAsync,
      fetchLanguageAsync,
    } = this.props;
    fetchGenresAsync();
    fetchLanguageAsync();
    fetchCountryAsync();

    let movie = movies.filter((m) => m._id === match.params.id);
    this.setState({
      allGenres,
      allCountries,
      languages,
      actors: movie[0]?.actor,
      data: {
        title: movie[0] ? movie[0].title : "",
        rating: movie[0] ? movie[0].rating : "",
        description: movie[0] ? movie[0].description : "",
        actor: movie[0] ? movie[0].actor : "",
        price: movie[0] ? movie[0].price : "",
        name: movie[0] ? movie[0].genre : "",
        isBanner: "No, don't set as banner",
        duration: movie[0] ? movie[0].duration : "",
        countryName: movie[0] ? movie[0].countryName : "",
        language: movie[0] ? movie[0].language : "",
        releaseType: movie[0] ? movie[0].releaseType : "",
        releaseYear: movie[0] ? movie[0].releaseYear : "",
        moviePictureURL: movie[0] ? movie[0].moviePictureURL : "",
        movieVideoURL: movie[0] ? movie[0].movieVideoURL : "",
        movieTrailerURL: movie[0] ? movie[0].movieTrailerURL : "",
      },
    });
  }

  render() {
    const { isLoading, trailerCompleted, videoCompleted } = this.state;
    const { match } = this.props;
    return isLoading ? (
      <MovieSpinner
        trailerCompleted={trailerCompleted}
        videoCompleted={videoCompleted}
      />
    ) : (
      <React.Fragment>
        <section className="auth">
          <div className="row auth-row no-gutter">
            <div
              className="col-md-12 bg-light-black"
              style={{ borderRadius: "8px" }}
            >
              <div
                className="container  d-flex h-100"
                style={{
                  maxHeight: "auto",
                  marginBottom: "20px",
                }}
              >
                {match.params.id !== "new" && (
                  <NavLink
                    style={{
                      marginTop: "100px",
                      marginLeft: "30px",
                      color: "#fff",
                    }}
                    to="/all-movies"
                  >
                    <AiOutlineArrowLeft size="20" /> Back
                  </NavLink>
                )}
                <span className="m-auto">
                  <form action="dashboard.html" onSubmit={this.handleSubmit}>
                    <span>Note: Field mark with * are complusory</span>
                    <br />
                    {this.renderInput("text", "title", "Title", "Title *")}
                    {/* {this.renderInput("text", "actor", "Actor", "Actor *")} */}
                    <div>
                      <span>Actors*</span>
                      <div className="tags-input-container">
                        {this.state.actors.map((actor, index) => (
                          <div className="tag-item" key={index}>
                            <span className="text">{actor}</span>
                            <span
                              className="close"
                              onClick={() => this.removeActor(index)}
                            >
                              &times;
                            </span>
                          </div>
                        ))}
                        <input
                          type="text"
                          onKeyDown={this.handleKeyDown}
                          placeholder="Enter actors name"
                          className="tags-input"
                        />
                      </div>
                    </div>
                    {this.renderTextArea(
                      "text",
                      "description",
                      "Description",
                      "Description *"
                    )}
                    {this.renderInput("number", "price", "Price", "Price *")}
                    {this.renderInput("number", "rating", "Rating", "Rating")}
                    {this.renderSelect(
                      "name",
                      ["Click to Select", ...this.state.allGenres],
                      "Genre *"
                    )}
                    <br />
                    {this.renderInput(
                      "number",
                      "duration",
                      "Duration in minutes",
                      "Duration in minutes"
                    )}
                    {this.renderSelect(
                      "isBanner",
                      [...this.state.isBanner],
                      "Set as Banner"
                    )}
                    <br />
                    {this.renderSelect(
                      "countryName",
                      ["Click to Select", ...this.state.allCountries],
                      "Country *"
                    )}
                    <br />
                    {this.renderSelect(
                      "language",
                      [
                        "Click to Select",
                        ...this.state.languages.filter(
                          (l) => l.countryName === this.state.data.countryName
                        ),
                      ],
                      "Language *"
                    )}
                    <br />
                    {this.renderSelect(
                      "releaseType",
                      ["Click to Select", ...this.state.type],
                      "Release Type"
                    )}
                    {this.renderInput(
                      "text",
                      "releaseYear",
                      "Release Year",
                      "Release Year"
                    )}
                    <div className="form-group">
                      <label className="form-label" htmlFor="">
                        Movie Banner picture
                      </label>
                      <div className="input-group input-group-lg mb-3">
                        <input
                          type="file"
                          name="movieBannerPictueURL"
                          onChange={this.handleBannerPictureSelect}
                          className="form-control"
                          placeholder="movie picture"
                          aria-label="movie picture"
                          aria-describedby="basic-addon2"
                        />
                      </div>
                    </div>
                    <br />
                    {this.renderButton("Update Movie")}
                  </form>
                </span>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectLoadingStatus,
  allGenres: selectAllGenres,
  movies: selectAllMovies,
  allCountries: selectAllCountries,
  languages: selectAllLanguages,
});

const mapDispatchToProps = (dispatch) => ({
  addMovieAsync: (payload) => dispatch(addMovieAsync(payload)),
  fetchGenresAsync: () => dispatch(fetchGenresAsync()),
  fetchCountryAsync: () => dispatch(fetchCountryAsync()),
  fetchLanguageAsync: () => dispatch(fetchLanguageAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMovie);
