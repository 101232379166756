import { createSelector } from "reselect";

export const selectUser = (state) => state.users;

export const selectUsers = createSelector(
  [selectUser],
  (user) => user.vertualUsers
);

export const selectAllUsers = createSelector(
  [selectUser],
  (user) => user.vertualAllUsers
);

export const selectLoadingStatus = createSelector(
  [selectUser],
  (user) => user.isLoading
);
