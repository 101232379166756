import React from "react";
import { NavLink } from "react-router-dom";

const TableUpdateButton = ({ user, label, route }) => {
  return (
    <React.Fragment>
      <label className="label label-primary add-to-cart">
        <NavLink to={`/${route}/${user._id}`}>
          <span className="badge bg-success btnLabel"> {label}</span>
        </NavLink>
      </label>
    </React.Fragment>
  );
};

export default TableUpdateButton;
