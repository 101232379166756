import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { labels, data, backgroundColor, borderColor } from "../../utils/const";

const MoviesChart = () => {
  const [chartData, setChartData] = useState({});
  // const [employeeSalary, setEmployeeSalary] = useState([]);
  // const [employeeAge, setEmployeeAge] = useState([]);

  const Chart = () => {
    setChartData({
      labels,
      datasets: [
        {
          label: ["Users"],
          data,
          backgroundColor,
          borderColor,
          borderWidth: 1,
          borderCapStyle: "butt",
          borderRadius: 6,
          fill: true,
        },
      ],
    });
  };

  useEffect(() => {
    Chart();
  }, []);

  return (
    <React.Fragment>
      <h3>
        <u>Movies evolution chart</u>
      </h3>
      <div>
        <Line
          data={chartData}
          options={{
            responsive: true,
            title: { text: "Orders", display: true },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          }}
        />
      </div>
    </React.Fragment>
  );
};

export default MoviesChart;
