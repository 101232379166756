import http from "./httpService";

const moviesEndpoint = "/movies";
const orderEndpoint = "/orders";
const longevityEndpoint = "/longevity";
const notificationEndpoint = "/notify";

export function addMovie(moviePayload) {
  if (moviePayload._id) {
    const body = { ...moviePayload };
    delete body._id;
    return http.put(moviesEndpoint + "/update/" + moviePayload._id, body);
  }
  return http.post(moviesEndpoint, moviePayload);
}

export function addOrder(orderPayload) {
  return http.post(orderEndpoint, orderPayload);
}

export function sendNotification(payload) {
  return http.post(notificationEndpoint, payload);
}

export function getMovies() {
  return http.get(moviesEndpoint);
}

export function getMovie(id) {
  return http.get(moviesEndpoint + "/" + id);
}

export function getOrders() {
  return http.get(orderEndpoint + "/all-orders");
}

export function addLongevity(payload) {
  return http.post(longevityEndpoint, payload);
}

export function uploadMoviePicture(payload) {
  return http.post(moviesEndpoint + "/upload-movie-image", payload);
}

export function deleteMovie(id) {
  return http.delete(moviesEndpoint + "/delete/" + id);
}
