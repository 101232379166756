import React from "react";

const TextArea = ({
  type,
  name,
  value,
  onChange,
  placeholder,
  label,
  error,
}) => {
  return (
    <div className="form-group">
      <label className="form-label" htmlFor="">
        {label}{" "}
        {error && (
          <span
            className="input-instruction"
            style={{ color: "red", fontSize: "12px" }}
          >
            {name !== "password_confirm" && error}
          </span>
        )}
      </label>
      <div className="input-group input-group-lg mb-3">
        <textarea
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          className="form-control"
          placeholder={placeholder}
        ></textarea>
      </div>
    </div>
  );
};

export default TextArea;
