export const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const data = [
  3400,
  2300,
  600,
  5000,
  9000,
  560,
  4900,
  2000,
  7090,
  1000,
  3600,
  6000,
];

export const backgroundColor = [
  "rgba(153, 102, 255, 0.2)",
  "rgba(54, 162, 235, 0.2)",
  "rgba(255, 206, 86, 0.2)",
  "rgba(75, 192, 192, 0.2)",
  "rgba(255, 99, 132, 0.2)",
  "rgba(255, 159, 64, 0.2)",
];

export const borderColor = [
  "rgba(153, 102, 255, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(255, 99, 132, 1)",
  "rgba(255, 159, 64, 1)",
];
