import React from "react";
import Joi from "joi-browser";
import Form from "../../components/authForm";
import http from "../../services/httpService";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import MovieSpinner from "../../components/movieSpinner";
import { selectLoadingStatus } from "../../redux/movies/movies.selector";
import { selectAllGenres } from "../../redux/moviesGenre/genres.selector";
import { uploadMoviePicture } from "../../services/movieServices";
import { addMovieAsync } from "../../redux/movies/movies.action";
import { fetchGenresAsync } from "../../redux/moviesGenre/genres.action";
import { toast } from "react-toastify";

const movieURL = "/movies/upload-video";

class AddMovieBanner extends Form {
  state = {
    data: {
      title: "",
      price: "",
      description: "",
      actor: "",
      name: "",
      duration: "",
      isBanner: "",
      releaseType: "",
      releaseYear: "",
      moviePictureURL: "",
      movieVideoURL: "",
      movieTrailerURL: "",
    },
    picture: false,
    trailerVideo: false,
    movieVideo: false,
    allGenres: [],
    isBanner: ["No, don't set as banner", "Yes, set as banner"],
    type: ["New Releases", "Trending", "Old Movie"],
    isLoading: false,
    trailerCompleted: 0,
    videoCompleted: 0,
    error: {},
  };

  schema = {
    title: Joi.string().min(3).max(255).required().label("Title"),
    price: Joi.number().min(0.99).max(100).required().label("Price"),
    description: Joi.string().min(3).max(255).required().label("Description"),
    actor: Joi.string().min(3).max(255).required().label("Actor"),
    name: Joi.string().required().label("Genre"),
    duration: Joi.string().label("Duration"),
    isBanner: Joi.string().label("Is Banner"),
    releaseType: Joi.string().max(255).label("Release Type"),
    releaseYear: Joi.string().max(30).label("Release Year"),
    //moviePictureURL: Joi.string().required().label("Movie Picture URL"),
    //movieTrailerURL: Joi.string().required().label("Movie Trailer URL"),
    //movieVideoURL: Joi.string().required().label("Movie Video URL"),
  };

  doSubmit = async () => {
    const { addMovieAsync } = this.props;

    const {
      title,
      price,
      description,
      actor,
      name,
      duration,
      //isBanner,
      releaseType,
      releaseYear,
    } = this.state.data;

    if (!this.state.picture) {
      toast.error("Please set a profile picture");
      return;
    }
    if (!this.state.trailerVideo) {
      toast.error("Please select a video for trailer");
      return;
    }
    // if (!this.state.movieVideo) {
    //   toast.error("Please select movie video");
    //   return;
    // }
    this.setState({ isLoading: true });

    const formData = new FormData();
    formData.append("file", this.state.picture, `${this.state.data.title}.png`);

    const formData2 = new FormData();
    formData2.append(
      "video",
      this.state.trailerVideo,
      `${this.state.trailerVideo.name}.mp4`
    );

    // const formData3 = new FormData();
    // formData3.append(
    //   "video",
    //   this.state.movieVideo,
    //   `${this.state.movieVideo.name}.mp4`
    // );

    try {
      const { data: pictureData } = await uploadMoviePicture(formData);

      const { data: trailerData } = await http.post(movieURL, formData2, {
        onUploadProgress: (progressEvent) => {
          console.log(progressEvent.loaded / progressEvent.total);
          this.setState({
            trailerCompleted: (
              (progressEvent.loaded * 100) /
              progressEvent.total
            ).toFixed(),
            videoCompleted: (
              (progressEvent.loaded * 100) /
              progressEvent.total
            ).toFixed(),
          });
        },
      });

      // const { data: videoData } = await http.post(movieURL, formData3, {
      //   onUploadProgress: (progressEvent) => {
      //     console.log(
      //       (progressEvent.loaded * 100) / (progressEvent.total * 100)
      //     );
      //     this.setState({
      //       videoCompleted: (
      //         (progressEvent.loaded * 100) /
      //         progressEvent.total
      //       ).toFixed(),
      //     });
      //   },
      // });

      addMovieAsync({
        title,
        price,
        description,
        actor,
        genre: name,
        duration,
        isBanner: true,
        //isBanner: isBanner === "Yes, set as banner" ? true : false,
        releaseType,
        releaseYear,
        movieVideoURL: trailerData.Location, //videoData.Location,
        movieTrailerURL: trailerData.Location,
        moviePictureURL: pictureData.fileLocation,
      });

      this.setState({
        data: {
          title: "",
          price: "",
          description: "",
          actor: "",
          name: "",
          duration: "",
          isBanner: "",
          releaseType: "",
          releaseYear: "",
          moviePictureURL: "",
          movieVideoURL: "",
          movieTrailerURL: "",
        },
        picture: false,
        movieVideo: false,
        trailerVideo: false,
        trailerCompleted: 0,
        videoCompleted: 0,
      });
      this.setState({ isLoading: false });
      toast.success("Movie successfully added...");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
        setTimeout(() => (window.location = "/add-movie-file/new"), 3000);
      }
    }
  };

  handlePictureSelect = (e) => {
    var picture = e.target.files[0];
    this.setState({ picture });
  };

  handleTrailerSelect = (e) => {
    var trailerVideo = e.target.files[0];
    this.setState({ trailerVideo });
  };

  // handleVideoSelect = (e) => {
  //   var movieVideo = e.target.files[0];
  //   this.setState({ movieVideo });
  // };

  componentDidMount() {
    document.title = "African Movies | Add Movie File";
    const { allGenres, fetchGenresAsync } = this.props;
    fetchGenresAsync();
    this.setState({
      allGenres,
      data: {},
    });
  }

  render() {
    const { isLoading, trailerCompleted } = this.state;
    return isLoading ? (
      <MovieSpinner
        trailerCompleted={trailerCompleted}
        videoCompleted={trailerCompleted}
      />
    ) : (
      <React.Fragment>
        <section className="auth">
          <div className="row auth-row no-gutter">
            <div
              className="col-md-12 bg-light-black"
              style={{ borderRadius: "8px" }}
            >
              <div
                className="container  d-flex h-100"
                style={{
                  maxHeight: "auto",
                  marginBottom: "20px",
                }}
              >
                <span className="m-auto">
                  <form action="dashboard.html" onSubmit={this.handleSubmit}>
                    <span>Note: Field mark with * are complusory</span>
                    <br />
                    {this.renderInput("text", "title", "Title", "Title *")}
                    {this.renderInput("text", "actor", "Actor", "Actor *")}
                    {this.renderInput(
                      "text",
                      "description",
                      "Description",
                      "Description *"
                    )}
                    {this.renderInput("text", "price", "Price", "Price *")}
                    {this.renderSelect(
                      "name",
                      ["Click to Select", ...this.state.allGenres],
                      "Genre *"
                    )}
                    {this.renderInput(
                      "text",
                      "duration",
                      "Duration",
                      "Duration"
                    )}
                    {/* {this.renderSelect(
                      "isBanner",
                      [...this.state.isBanner],
                      "Set as Banner"
                    )}
                    <br /> */}
                    {this.renderSelect(
                      "releaseType",
                      ["Click to Select", ...this.state.type],
                      "Release Type"
                    )}
                    {this.renderInput(
                      "text",
                      "releaseYear",
                      "Release Year",
                      "Release Year"
                    )}
                    {/* {this.renderInput(
                      "file",
                      "moviePictureURL",
                      "Movie Picture",
                      "Movie Picture *"
                    )} */}
                    <div className="form-group">
                      <label className="form-label" htmlFor="">
                        Banner Picture * (2862 x 1438)
                      </label>
                      <div className="input-group input-group-lg mb-3">
                        <input
                          type="file"
                          name="moviePictueURL"
                          onChange={this.handlePictureSelect}
                          className="form-control"
                          placeholder="movie picture"
                          aria-label="movie picture"
                          aria-describedby="basic-addon2"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="">
                        Site Banner Video *
                      </label>
                      <div className="input-group input-group-lg mb-3">
                        <input
                          type="file"
                          name="movieTrailerURL"
                          onChange={this.handleTrailerSelect}
                          className="form-control"
                          placeholder="movie trailer"
                          aria-label="movie trailer"
                          aria-describedby="basic-addon2"
                        />
                      </div>
                    </div>
                    {/* <div className="form-group">
                      <label className="form-label" htmlFor="">
                        Movie Video *
                      </label>
                      <div className="input-group input-group-lg mb-3">
                        <input
                          type="file"
                          name="movieVideoURL"
                          onChange={this.handleVideoSelect}
                          className="form-control"
                          placeholder="movie video"
                          aria-label="movie video"
                          aria-describedby="basic-addon2"
                        />
                      </div>
                    </div> */}
                    {/* {this.renderInput(
                      "text",
                      "movieTrailerURL",
                      "Movie Trailer",
                      "Movie Trailer URL *"
                    )}
                    {this.renderInput(
                      "text",
                      "movieVideoURL",
                      "Movie Video",
                      "Movie Video URL *"
                    )} */}
                    <br />
                    {this.renderButton("Add Site Banner")}
                  </form>
                </span>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectLoadingStatus,
  allGenres: selectAllGenres,
});

const mapDispatchToProps = (dispatch) => ({
  addMovieAsync: (payload) => dispatch(addMovieAsync(payload)),
  fetchGenresAsync: () => dispatch(fetchGenresAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMovieBanner);
