import React from "react";
import MoviesSyncTable from "../../components/moviesSyncTable";

function MoviesSync() {
  return (
    <React.Fragment>
      <h3>Movies Sync accross African movies and Zulucast</h3>
      <h5>
        Here you have all movies in Zulucast and not in African Movies, you can
        add them to the African movies site.
      </h5>
      <hr />
      <br />
      <MoviesSyncTable />
    </React.Fragment>
  );
}

export default MoviesSync;
