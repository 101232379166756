import React from "react";
import Joi from "joi-browser";
import Form from "../../components/authForm";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import WithSpinner from "../../components/withSpinner";
import {
  addCountryAsync,
  fetchCountryAsync,
} from "../../redux/country/country.action";

import { getCountry } from "../../services/countryService";
import { selectLoadingStatus } from "../../redux/country/country.selector";
import { selectAllCountries } from "../../redux/country/country.selector";

class AddCountry extends Form {
  state = {
    data: {
      countryName: "",
    },
    error: {},
  };

  schema = {
    _id: Joi.string(),
    countryName: Joi.string().min(3).max(255).required().label("Country Name"),
  };

  doSubmit = async () => {
    const { addCountryAsync } = this.props;
    addCountryAsync(this.state.data);
    this.setState({
      data: {
        countryName: "",
      },
    });
  };

  async componentDidMount() {
    document.title = "African Movies | Add Country";
    const { match } = this.props;
    const id = match.params.id;

    const { fetchCountryAsync } = this.props;
    fetchCountryAsync();

    if (id === "new") {
      this.setState({
        data: {
          countryName: "",
        },
      });
    } else {
      const { data: country } = await getCountry(id);
      this.setState({
        data: country,
      });
    }
  }

  render() {
    const { isLoading, match } = this.props;
    const btnLabel =
      match.params.id === "new" ? "Add Country" : "Update Country";

    return isLoading ? (
      <WithSpinner />
    ) : (
      <React.Fragment>
        <section className="auth">
          <div className="row auth-row no-gutter">
            <div
              className="col-md-12 bg-light-black"
              style={{ borderRadius: "8px" }}
            >
              <div className="container  d-flex h-100" style={{ width: 300 }}>
                <span className="m-auto">
                  <form
                    action="dashboard.html"
                    onSubmit={this.handleSubmit}
                    style={{ marginTop: "-80px" }}
                  >
                    {match.params.id !== "new" ? (
                      <span>
                        Kindly fill the field you want to update then save
                      </span>
                    ) : (
                      <section>
                        <span>
                          Add a county (When country is Added the end user can
                          filter movies per country on african movies.)
                        </span>
                        <br />
                        <br />
                      </section>
                    )}
                    {this.renderInput(
                      "text",
                      "countryName",
                      "Country name",
                      "Country name"
                    )}

                    <br />
                    {this.renderButton(`${btnLabel}`)}
                  </form>
                </span>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectLoadingStatus,
  countries: selectAllCountries,
});

const mapDispatchToProps = (dispatch) => ({
  addCountryAsync: (payload) => dispatch(addCountryAsync(payload)),
  fetchCountryAsync: () => dispatch(fetchCountryAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCountry);
