import React from "react";
import Joi from "joi-browser";
import Form from "../../components/authForm";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import MovieSpinner from "../../components/movieSpinner";
import http from "../../services/httpService";

import { NavLink } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import {
  selectLoadingStatus,
  selectAllMovies,
} from "../../redux/movies/movies.selector";
import {
  fetchCountryAsync,
  fetchLanguageAsync,
} from "../../redux/country/country.action";
import {
  selectAllCountries,
  selectAllLanguages,
} from "../../redux/country/country.selector";
import { selectAllGenres } from "../../redux/moviesGenre/genres.selector";
import { uploadMoviePicture } from "../../services/movieServices";
import { addMovieAsync } from "../../redux/movies/movies.action";
import { fetchGenresAsync } from "../../redux/moviesGenre/genres.action";
import { toast } from "react-toastify";

const movieURL = "/movies/upload-video";

class UpdateMovieFiles extends Form {
  state = {
    data: {
      title: "",
      price: "",
      description: "",
      actor: "",
      name: "",
      countryName: "",
      language: "",
      duration: "",
      isBanner: "",
      releaseType: "",
      releaseYear: "",
      moviePictureURL: "",
      movieVideoURL: "",
      movieTrailerURL: "",
    },
    picture: false,
    trailerVideo: false,
    movieVideo: false,
    allGenres: [],
    allCountries: [],
    languages: [],
    isBanner: ["No, don't set as banner", "Yes, set as banner"],
    type: ["New Releases", "Trending", "Old Movie"],
    isLoading: false,
    trailerCompleted: 0,
    videoCompleted: 0,
    error: {},
  };

  schema = {
    title: Joi.string().min(3).max(255).required().label("Title"),
    price: Joi.number().min(0).required().label("Price"),
    description: Joi.string().min(3).max(255).required().label("Description"),
    actor: Joi.string().min(3).max(255).required().label("Actor"),
    name: Joi.string().required().label("Genre"),
    duration: Joi.string().label("Duration"),
    isBanner: Joi.string().label("Is Banner"),
    releaseType: Joi.string().max(255).label("Release Type"),
    releaseYear: Joi.string().max(30).label("Release Year"),
    moviePictureURL: Joi.string().required().label("Movie Picture URL"),
    movieTrailerURL: Joi.string().required().label("Movie Trailer URL"),
    movieVideoURL: Joi.string().required().label("Movie Video URL"),
    countryName: Joi.string().min(3).max(255).required(),
    language: Joi.string().min(3).max(255).required(),
  };

  handlePictureSelect = (e) => {
    var picture = e.target.files[0];
    this.setState({ picture });
  };

  handleTrailerSelect = (e) => {
    var trailerVideo = e.target.files[0];
    this.setState({ trailerVideo });
  };

  handleVideoSelect = (e) => {
    var movieVideo = e.target.files[0];
    this.setState({ movieVideo });
  };

  doSubmit = async () => {
    const { addMovieAsync, match } = this.props;

    const {
      title,
      price,
      description,
      actor,
      name,
      duration,
      countryName,
      language,
      isBanner,
      releaseType,
      releaseYear,
    } = this.state.data;

    if (!this.state.picture) {
      toast.error("Please set a profile picture");
      return;
    }
    if (!this.state.trailerVideo) {
      toast.error("Please select a video for trailer");
      return;
    }
    if (!this.state.movieVideo) {
      toast.error("Please select movie video");
      return;
    }

    this.setState({ isLoading: true });

    const formData = new FormData();
    formData.append("file", this.state.picture, `${this.state.data.title}.png`);

    const formData2 = new FormData();
    formData2.append(
      "video",
      this.state.trailerVideo,
      `${this.state.trailerVideo.name}.mp4`
    );

    const formData3 = new FormData();
    formData3.append(
      "video",
      this.state.movieVideo,
      `${this.state.movieVideo.name}.mp4`
    );

    const { data: pictureData } = await uploadMoviePicture(formData);

    const { data: trailerData } = await http.post(movieURL, formData2, {
      onUploadProgress: (progressEvent) => {
        console.log(progressEvent.loaded / progressEvent.total);
        this.setState({
          trailerCompleted: (
            (progressEvent.loaded * 100) /
            progressEvent.total
          ).toFixed(),
        });
      },
    });

    const { data: videoData } = await http.post(movieURL, formData3, {
      onUploadProgress: (progressEvent) => {
        console.log((progressEvent.loaded * 100) / (progressEvent.total * 100));
        this.setState({
          videoCompleted: (
            (progressEvent.loaded * 100) /
            progressEvent.total
          ).toFixed(),
        });
      },
    });

    addMovieAsync({
      _id: match.params.id,
      title,
      price,
      description,
      actor,
      genre: name,
      countryName,
      language,
      duration,
      isBanner: isBanner === "Yes, set as banner" ? true : false,
      releaseType,
      releaseYear,
      movieVideoURL: videoData.Location,
      movieTrailerURL: trailerData.Location,
      moviePictureURL: pictureData.fileLocation,
    });

    this.setState({
      data: {
        title: "",
        price: "",
        description: "",
        actor: "",
        name: "",
        duration: "",
        isBanner: "",
        countryName: "",
        language: "",
        releaseType: "",
        releaseYear: "",
        moviePictureURL: "",
        movieVideoURL: "",
        movieTrailerURL: "",
      },
      picture: false,
      movieVideo: false,
      trailerVideo: false,
      trailerCompleted: 0,
      videoCompleted: 0,
    });
    this.setState({ isLoading: false });
    toast.success("Movie successfully updated...");
  };

  handlePictureSelect = (e) => {
    var picture = e.target.files[0];
    this.setState({ picture });
  };

  handleTrailerSelect = (e) => {
    var trailerVideo = e.target.files[0];
    this.setState({ trailerVideo });
  };

  handleVideoSelect = (e) => {
    var movieVideo = e.target.files[0];
    this.setState({ movieVideo });
  };

  async componentDidMount() {
    document.title = "African Movies | update Movie";
    const {
      allGenres,
      fetchGenresAsync,
      match,
      movies,
      languages,
      allCountries,
      fetchCountryAsync,
      fetchLanguageAsync,
    } = this.props;
    fetchGenresAsync();
    fetchCountryAsync();
    fetchLanguageAsync();
    let movie = movies.filter((m) => m._id === match.params.id);
    this.setState({
      allGenres,
      languages,
      allCountries,
      data: {
        title: movie[0] ? movie[0].title : "",
        description: movie[0] ? movie[0].description : "",
        actor: movie[0] ? movie[0].actor : "",
        price: movie[0] ? movie[0].price : "",
        name: movie[0] ? movie[0].genre : "",
        countryName: movie[0] ? movie[0].countryName : "",
        language: movie[0] ? movie[0].language : "",
        isBanner: "No, don't set as banner",
        duration: movie[0] ? movie[0].duration : "",
        releaseType: movie[0] ? movie[0].releaseType : "",
        releaseYear: movie[0] ? movie[0].releaseYear : "",
        moviePictureURL: movie[0] ? movie[0].moviePictureURL : "",
        movieVideoURL: movie[0] ? movie[0].movieVideoURL : "",
        movieTrailerURL: movie[0] ? movie[0].movieTrailerURL : "",
      },
    });
  }

  render() {
    const { match } = this.props;
    const { isLoading, trailerCompleted, videoCompleted } = this.state;
    return isLoading ? (
      <MovieSpinner
        trailerCompleted={trailerCompleted}
        videoCompleted={videoCompleted}
      />
    ) : (
      <React.Fragment>
        <section className="auth">
          <div className="row auth-row no-gutter">
            <div
              className="col-md-12 bg-light-black"
              style={{ borderRadius: "8px" }}
            >
              <div
                className="container  d-flex h-100"
                style={{
                  maxHeight: "auto",
                  marginBottom: "20px",
                }}
              >
                {match.params.id !== "new" && (
                  <NavLink
                    style={{
                      marginTop: "100px",
                      marginLeft: "30px",
                      color: "#fff",
                    }}
                    to="/all-movies"
                  >
                    <AiOutlineArrowLeft size="20" /> Back
                  </NavLink>
                )}
                <span className="m-auto">
                  <form action="dashboard.html" onSubmit={this.handleSubmit}>
                    <span>Note: Field mark with * are complusory</span>
                    <br />
                    <div className="form-group">
                      <label className="form-label" htmlFor="">
                        Movie Picture *
                      </label>
                      <div className="input-group input-group-lg mb-3">
                        <input
                          type="file"
                          name="moviePictueURL"
                          onChange={this.handlePictureSelect}
                          className="form-control"
                          placeholder="movie picture"
                          aria-label="movie picture"
                          aria-describedby="basic-addon2"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="">
                        Trailer Video *
                      </label>
                      <div className="input-group input-group-lg mb-3">
                        <input
                          type="file"
                          name="movieTrailerURL"
                          onChange={this.handleTrailerSelect}
                          className="form-control"
                          placeholder="movie trailer"
                          aria-label="movie trailer"
                          aria-describedby="basic-addon2"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="">
                        Movie Video *
                      </label>
                      <div className="input-group input-group-lg mb-3">
                        <input
                          type="file"
                          name="movieVideoURL"
                          onChange={this.handleVideoSelect}
                          className="form-control"
                          placeholder="movie video"
                          aria-label="movie video"
                          aria-describedby="basic-addon2"
                        />
                      </div>
                    </div>
                    <br />
                    {this.renderButton("Update Movie")}
                  </form>
                </span>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectLoadingStatus,
  allGenres: selectAllGenres,
  movies: selectAllMovies,
  allCountries: selectAllCountries,
  languages: selectAllLanguages,
});

const mapDispatchToProps = (dispatch) => ({
  addMovieAsync: (payload) => dispatch(addMovieAsync(payload)),
  fetchGenresAsync: () => dispatch(fetchGenresAsync()),
  fetchCountryAsync: () => dispatch(fetchCountryAsync()),
  fetchLanguageAsync: () => dispatch(fetchLanguageAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateMovieFiles);
