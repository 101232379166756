import React from "react";
import Joi from "joi-browser";
import Form from "../../components/authForm";
import generator from "generate-password";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import WithSpinner from "./../../components/withSpinner";
import { registerUserAsync } from "../../redux/users/users.action";
import { selectLoadingStatus } from "../../redux/users/users.selector";
import { getUser } from "../../services/usersService";
import { NavLink } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";

class AddCustomer extends Form {
  state = {
    data: {
      username: "",
      email: "",
      password: "",
    },
    error: {},
  };

  schema = {
    _id: Joi.string(),
    username: Joi.string().min(3).max(20).required().label("Username"),
    email: Joi.string().email().required().label("Email"),
    password: Joi.string().label("Password"),
  };

  doSubmit = async () => {
    const { registerUserAsync } = this.props;
    registerUserAsync({
      ...this.state.data,
      password_confirm: this.state.data.password,
    });
    this.setState({
      data: {
        username: "",
        email: "",
        password: "",
      },
    });
  };

  async componentDidMount() {
    document.title = "African Movies | Add Customer";
    const { match } = this.props;
    const id = match.params.id;
    if (id === "new") {
      var password = generator.generate({
        length: 10,
        numbers: true,
        symbols: true,
        lowercase: true,
        uppercase: true,
        strict: true,
      });
      this.setState({
        data: {
          username: "",
          email: "",
          password,
        },
      });
    } else {
      const { data: user } = await getUser(id);
      this.setState({
        data: user,
      });
      console.log("User", this.state);
    }
  }

  render() {
    const { isLoading, match } = this.props;
    const btnLabel = match.params.id === "new" ? "Add User" : "Update User";
    return isLoading ? (
      <WithSpinner />
    ) : (
      <React.Fragment>
        <section className="auth">
          <div className="row auth-row no-gutter">
            <div
              className="col-md-12 bg-light-black"
              style={{ borderRadius: "8px" }}
            >
              <div className="container  d-flex h-100">
                {match.params.id !== "new" && (
                  <NavLink
                    style={{
                      marginTop: "100px",
                      marginLeft: "30px",
                      color: "#fff",
                    }}
                    to="/customers"
                  >
                    <AiOutlineArrowLeft size="20" /> Back
                  </NavLink>
                )}
                <span className="m-auto">
                  <form
                    action="dashboard.html"
                    onSubmit={this.handleSubmit}
                    style={{ marginTop: "-80px" }}
                  >
                    {match.params.id !== "new" && (
                      <span>
                        Kindly fill the field you want to update and save
                      </span>
                    )}
                    <br />
                    {this.renderInput(
                      "text",
                      "username",
                      "Username",
                      "Username"
                    )}
                    {this.renderInput("text", "email", "Email", "Email")}

                    {/* {this.renderSelect(
                      "role",
                      ["Click to Select", ...this.state.role],
                      "Role"
                    )} */}
                    {match.params.id === "new" && (
                      <div>
                        <br />
                        <span>Please note the generated password</span>
                        <br />
                        <span>
                          Password:{" "}
                          <span
                            style={{
                              color: "white",
                              fontWeight: "bolder",
                              fontSize: "20px",
                            }}
                          >
                            {this.state.data.password}
                          </span>
                        </span>
                      </div>
                    )}
                    <br />
                    {this.renderButton(`${btnLabel}`)}
                  </form>
                </span>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectLoadingStatus,
});

const mapDispatchToProps = (dispatch) => ({
  registerUserAsync: (payload) => dispatch(registerUserAsync(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);
