import React from "react";
import Joi from "joi-browser";
import Form from "../../components/authForm";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import WithSpinner from "./../../components/withSpinner";
import { selectLoadingStatus } from "../../redux/movies/movies.selector";
import { addLongevityAsync } from "../../redux/movies/movies.action";

class Settings extends Form {
  state = {
    data: {
      longevity: "",
      slidesAsBanner: "",
    },
    hover: ["Set slides as banner", "Set video instead"],
    error: {},
  };

  schema = {
    longevity: Joi.number().min(1).max(99).required().label("Longevity"),
    slidesAsBanner: Joi.required().label("slidesAsBanner"),
  };

  doSubmit = async () => {
    const { addLongevityAsync } = this.props;
    addLongevityAsync(this.state.data);
    this.setState({
      data: {
        longevity: "",
        slidesAsBanner: "",
      },
    });
  };

  componentDidMount() {
    document.title = "African Movies | Settings";
  }

  render() {
    const { isLoading } = this.props;
    return isLoading ? (
      <WithSpinner />
    ) : (
      <React.Fragment>
        <section className="auth">
          <div className="row auth-row no-gutter">
            <div
              className="col-md-12 bg-light-black"
              style={{ borderRadius: "8px" }}
            >
              <div className="container  d-flex h-100">
                <span className="m-auto">
                  <form
                    action="dashboard.html"
                    onSubmit={this.handleSubmit}
                    style={{ marginTop: "-80px" }}
                  >
                    {this.renderInput(
                      "number",
                      "longevity",
                      "Longevity",
                      "Longevity (It's in days)"
                    )}
                    {this.renderSelect(
                      "slidesAsBanner",
                      ["Click to Select", ...this.state.hover],
                      "Banner"
                    )}
                    <br />

                    {this.renderButton("Save")}
                  </form>
                </span>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectLoadingStatus,
});

const mapDispatchToProps = (dispatch) => ({
  addLongevityAsync: (payload) => dispatch(addLongevityAsync(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
