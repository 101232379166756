import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import { connect } from "react-redux";
import { deleteMovieAsync } from "../../redux/movies/movies.action";

const DeleteMovieBtn = ({ movie, label, deleteMovie }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deleteMovie(movie._id);
    setOpen(false);
  };

  return (
    <React.Fragment>
      <label
        className="label label-primary add-to-cart"
        style={{ cursor: "pointer" }}
        onClick={handleClickOpen}
      >
        <span className="badge bg-danger btnLabel"> {label}</span>
      </label>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"African Movies Admin Dashboard."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            After successfull deletion of {movie.title}, it will no longer be
            available in African Movies. Are you sure you want to proceed ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  deleteMovie: (movieId) => dispatch(deleteMovieAsync(movieId)),
});

export default connect(null, mapDispatchToProps)(DeleteMovieBtn);
