const movieActionTypes = {
  FETCH_MOVIES_START: "FETCH_MOVIES_START",
  FETCH_MOVIES_SUCCESS: "FETCH_MOVIES_SUCCESS",
  FETCH_MOVIES_FAILURE: "FETCH_MOVIES_FAILURE",
  ADD_TO_ORDER_START: "ADD_TO_ORDER_START",
  ADD_TO_ORDER_SUCCESS: "ADD_TO_ORDER_SUCCESS",
  ADD_TO_ORDER_FAILURE: "ADD_TO_ORDER_FAILURE",
  FETCH_ORDERS_START: "FETCH_ORDERS_START",
  FETCH_ORDERS_SUCCESS: "FETCH_ORDERS_SUCCESS",
  FETCH_ORDERS_FAILURE: "FETCH_ORDERS_FAILURE",
  ADD_MOVIE_LONGEVITY_START: "ADD_MOVIE_LONGEVITY_START",
  ADD_MOVIE_LONGEVITY_SUCCESS: "ADD_MOVIE_LONGEVITY_SUCCESS",
  ADD_MOVIE_LONGEVITY_FAILURE: "ADD_MOVIE_LONGEVITY_FAILURE",
  ADD_MOVIE_START: "ADD_MOVIE_START",
  ADD_MOVIE_SUCCESS: "ADD_MOVIE_SUCCESS",
  ADD_MOVIE_FAILURE: "ADD_MOVIE_FAILURE",
  SEARCH_MOVIE: "SEARCH_MOVIE",
  SEARCH_ORDER: "SEARCH_ORDER",
  DELETE_MOVIE_START: "DELETE_MOVIE_START",
  DELETE_MOVIE_SUCCESS: "DELETE_MOVIE_SUCCESS",
  DELETE_MOVIE_FAILURE: "DELETE_MOVIE_FAILURE",
};

export default movieActionTypes;
