import moment from "moment";

export const getMoviesCount = (movies, addedBy) =>
  movies.filter((movie) => movie.uploadedBy === addedBy).length;

export const getReportMovies = (movies, startDate, endDate) =>
  movies.filter(
    (movie) =>
      moment(movie.uploadDate).isSameOrAfter(moment(startDate)) &&
      moment(movie.uploadDate).isSameOrBefore(moment(endDate))
  );
