import React, { Component } from "react";
import LanguagesTable from "../../components/languagesTable";

class AllLanguages extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <h1>Countries Table</h1>
        <hr />
        <br />
        <LanguagesTable />
      </React.Fragment>
    );
  }
}

export default AllLanguages;
