import React from "react";
import Joi from "joi-browser";
import Form from "../../components/authForm";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import WithSpinner from "./../../components/withSpinner";
import { selectLoadingStatus } from "../../redux/movies/movies.selector";
import { selectAllGenres } from "./../../redux/moviesGenre/genres.selector";
import { uploadMoviePicture } from "../../services/movieServices";
import { addMovieAsync } from "../../redux/movies/movies.action";
import { fetchGenresAsync } from "../../redux/moviesGenre/genres.action";
import { toast } from "react-toastify";

class AddMovie extends Form {
  state = {
    data: {
      title: "",
      price: "",
      description: "",
      actor: "",
      name: "",
      duration: "",
      isBanner: "",
      releaseType: "",
      releaseYear: "",
      moviePictureURL: "",
      movieVideoURL: "",
      movieTrailerURL: "",
    },
    picture: false,
    allGenres: [],
    isBanner: ["No, don't set as banner", "Yes, set as banner"],
    type: ["New Releases", "Trending", "Old Movie"],
    isLoading: false,
    error: {},
  };

  schema = {
    title: Joi.string().min(3).max(255).required().label("Title"),
    price: Joi.number().min(0.99).max(100).required().label("Price"),
    description: Joi.string().min(3).max(255).required().label("Description"),
    actor: Joi.string().min(3).max(255).required().label("Actor"),
    name: Joi.string().required().label("Genre"),
    duration: Joi.string().label("Duration"),
    isBanner: Joi.string().label("Is Banner"),
    releaseType: Joi.string().max(255).label("Release Type"),
    releaseYear: Joi.string().max(30).label("Release Year"),
    //moviePictureURL: Joi.string().required().label("Movie Picture URL"),
    movieTrailerURL: Joi.string().required().label("Movie Trailer URL"),
    movieVideoURL: Joi.string().required().label("Movie Video URL"),
  };

  doSubmit = async () => {
    this.setState({ isLoading: true });
    const { addMovieAsync } = this.props;
    const {
      title,
      price,
      description,
      actor,
      name,
      duration,
      isBanner,
      releaseType,
      releaseYear,
      movieVideoURL,
      movieTrailerURL,
    } = this.state.data;
    if (!this.state.picture) {
      toast.error("Please set a profile picture");
      return;
    }
    const formData = new FormData();
    formData.append("file", this.state.picture, `${this.state.data.title}.png`);
    try {
      const { data } = await uploadMoviePicture(formData);
      addMovieAsync({
        title,
        price,
        description,
        actor,
        genre: name,
        duration,
        isBanner: isBanner === "Yes, set as banner" ? true : false,
        releaseType,
        releaseYear,
        movieVideoURL,
        movieTrailerURL,
        moviePictureURL: data.fileLocation,
      });
      this.setState({
        data: {
          title: "",
          price: "",
          description: "",
          actor: "",
          name: "",
          duration: "",
          isBanner: "",
          releaseType: "",
          releaseYear: "",
          moviePictureURL: "",
          movieVideoURL: "",
          movieTrailerURL: "",
        },
        picture: false,
      });
      this.setState({ isLoading: false });
      toast.success("Movie successfully added...");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
        setTimeout(() => (window.location = "/add-movie"), 3000);
      }
    }
  };

  handlePictureSelect = (e) => {
    var picture = e.target.files[0];
    this.setState({ picture });
  };

  componentDidMount() {
    document.title = "African Movies | Add Movie";
    const { allGenres, fetchGenresAsync } = this.props;
    fetchGenresAsync();
    this.setState({
      allGenres,
      data: {},
    });
  }

  render() {
    const { isLoading } = this.state;

    return isLoading ? (
      <WithSpinner />
    ) : (
      <React.Fragment>
        <section className="auth">
          <div className="row auth-row no-gutter">
            <div
              className="col-md-12 bg-light-black"
              style={{ borderRadius: "8px" }}
            >
              <div
                className="container  d-flex h-100"
                style={{ maxHeight: "auto", marginBottom: "20px" }}
              >
                <span className="m-auto">
                  <form action="dashboard.html" onSubmit={this.handleSubmit}>
                    <span>Note: Field mark with * are complusory</span>
                    <br />
                    {this.renderInput("text", "title", "Title", "Title *")}
                    {this.renderInput("text", "actor", "Actor", "Actor *")}
                    {this.renderInput(
                      "text",
                      "description",
                      "Description",
                      "Description *"
                    )}
                    {this.renderInput("text", "price", "Price", "Price *")}
                    {this.renderSelect(
                      "name",
                      ["Click to Select", ...this.state.allGenres],
                      "Genre *"
                    )}
                    {this.renderInput(
                      "text",
                      "duration",
                      "Duration",
                      "Duration"
                    )}
                    {this.renderSelect(
                      "isBanner",
                      [...this.state.isBanner],
                      "Set as Banner"
                    )}
                    <br />
                    {this.renderSelect(
                      "releaseType",
                      ["Click to Select", ...this.state.type],
                      "Release Type"
                    )}
                    {this.renderInput(
                      "text",
                      "releaseYear",
                      "Release Year",
                      "Release Year"
                    )}
                    {/* {this.renderInput(
                      "file",
                      "moviePictureURL",
                      "Movie Picture",
                      "Movie Picture *"
                    )} */}
                    <div className="form-group">
                      <label className="form-label" htmlFor="">
                        Movie Picture *
                      </label>
                      <div className="input-group input-group-lg mb-3">
                        <input
                          type="file"
                          name="moviePictueURL"
                          onChange={this.handlePictureSelect}
                          className="form-control"
                          placeholder="movie picture"
                          aria-label="movie picture"
                          aria-describedby="basic-addon2"
                        />
                      </div>
                    </div>
                    {this.renderInput(
                      "text",
                      "movieTrailerURL",
                      "Movie Trailer",
                      "Movie Trailer URL *"
                    )}
                    {this.renderInput(
                      "text",
                      "movieVideoURL",
                      "Movie Video",
                      "Movie Video URL *"
                    )}
                    <br />
                    {this.renderButton("Add Movie")}
                  </form>
                </span>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectLoadingStatus,
  allGenres: selectAllGenres,
});

const mapDispatchToProps = (dispatch) => ({
  addMovieAsync: (payload) => dispatch(addMovieAsync(payload)),
  fetchGenresAsync: () => dispatch(fetchGenresAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMovie);
