import React, { Component } from "react";
import Moment from "react-moment";

class TableBody extends Component {
  state = {};
  renderColumn(element, column) {
    const pathValue = element[column.path];
    if (column.path === "actor")
      return (
        <span>
          {pathValue.map((i) => (
            <span>{i + ", "}</span>
          ))}
        </span>
      );
    if (column.path === "registrationDate" || column.path === "uploadDate")
      return <Moment format="D MMM YYYY">{pathValue}</Moment>;
    if (column.buttons) return column.buttons(element);
    return pathValue;
  }
  render() {
    const { elements, columns } = this.props;
    return (
      <React.Fragment>
        <tbody>
          {elements.map((element) => (
            <tr key={element._id}>
              {columns.map((column) => (
                <td key={element._id + column.title}>
                  {this.renderColumn(element, column)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </React.Fragment>
    );
  }
}

export default TableBody;
