import React, { Component } from "react";
import TableBody from "./tableBody";
import TableHeader from "./tableHeader";
import TableSearch from "./table-search.component";
//import ZuluSearch from "./z-search";

class Table extends Component {
  state = {};
  render() {
    const { elements, columns, title, search, show } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-lg-12 col-xxl-12 d-flex">
            <div className="card flex-fill">
              <div className="card-header">
                <h5 className="card-title mb-0">
                  {title} | Total : {elements.length}
                </h5>
                {show === "search" && <TableSearch search={search} />}
                {/* {show === "af_search" && <ZuluSearch search={search} />} */}
              </div>
              {elements.length > 0 ? (
                <table className="table table-hover my-0">
                  <TableHeader columns={columns} />
                  <TableBody columns={columns} elements={elements} />
                </table>
              ) : (
                <h3>No Data in table yet.</h3>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Table;
