import React, { Component } from "react";
import CountriesTable from "./../../components/countriesTable";

class AllCountries extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <h1>Countries Table</h1>
        <hr />
        <br />
        <CountriesTable />
      </React.Fragment>
    );
  }
}

export default AllCountries;
