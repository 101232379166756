import React, { Component } from "react";
import CustomersTable from "./../../components/customersTable";

class Customers extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <h1>Your Customers Table</h1>
        <hr />
        <br />
        <CustomersTable />
      </React.Fragment>
    );
  }
}

export default Customers;
