import React, { Component } from "react";

class Dashboard extends Component {
  state = {};

  capitalizeFirstLetter(value) {
    value = value.substring(1);
    let s = value.split("/");
    value = s[0];
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  render() {
    const { pathname } = this.props.location;
    return (
      <React.Fragment>
        <div className="row mb-2 mb-xl-3">
          <div className="col-auto d-none d-sm-block">
            <h3>
              <strong>{this.capitalizeFirstLetter(pathname)}</strong>
            </h3>
          </div>

          <div className="col-auto ml-auto text-right mt-n1">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb bg-transparent p-0 mt-1 mb-0">
                <li className="breadcrumb-item">
                  <a href="/">African Movies</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {this.capitalizeFirstLetter(pathname)}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
