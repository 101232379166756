import React from "react";
import Joi from "joi-browser";
import Form from "../../components/authForm";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import WithSpinner from "../../components/withSpinner";
import {
  addLanguageAsync,
  fetchLanguageAsync,
  fetchCountryAsync,
} from "../../redux/country/country.action";

import { getLanguage } from "../../services/countryService";
import { selectLoadingStatus } from "../../redux/country/country.selector";
import {
  selectAllLanguages,
  selectAllCountries,
} from "../../redux/country/country.selector";

class AddLanguages extends Form {
  state = {
    data: {
      language: "",
      countryName: "",
    },
    allCountries: [],
    error: {},
  };

  schema = {
    _id: Joi.string(),
    language: Joi.string().min(3).max(255).required().label("Language"),
    countryName: Joi.string().min(3).max(255).required().label("Country Name"),
  };

  doSubmit = async () => {
    const { addLanguageAsync } = this.props;
    addLanguageAsync(this.state.data);
    this.setState({
      data: {
        language: "",
        countryName: "",
      },
    });
  };

  async componentDidMount() {
    document.title = "African Movies | Add Language";
    const { match } = this.props;
    const id = match.params.id;
    const { fetchLanguageAsync, fetchCountryAsync, allCountries } = this.props;
    fetchLanguageAsync();
    fetchCountryAsync(); //622073763
    if (id === "new") {
      this.setState({
        allCountries,
      });
    } else {
      const { data: language } = await getLanguage(id);
      this.setState({
        allCountries,
        data: language,
      });
    }
  }

  render() {
    const { isLoading, match } = this.props;
    const btnLabel =
      match.params.id === "new" ? "Add Language" : "Update Language";

    return isLoading ? (
      <WithSpinner />
    ) : (
      <React.Fragment>
        <section className="auth">
          <div className="row auth-row no-gutter">
            <div
              className="col-md-12 bg-light-black"
              style={{ borderRadius: "8px" }}
            >
              <div className="container  d-flex h-100" style={{ width: 300 }}>
                <span className="m-auto">
                  <form
                    action="dashboard.html"
                    onSubmit={this.handleSubmit}
                    style={{ marginTop: "-80px" }}
                  >
                    {match.params.id !== "new" ? (
                      <span>
                        Kindly fill the field you want to update then save
                      </span>
                    ) : (
                      <div>
                        <span>
                          Add alanguage (When language is Added the end user can
                          filter movies per language and country on the zulucast
                          main application.)
                        </span>
                        <br />
                        <br />
                      </div>
                    )}
                    {this.renderSelect(
                      "countryName",
                      ["Click to Select", ...this.state.allCountries],
                      "Country"
                    )}
                    <br />
                    {this.renderInput(
                      "text",
                      "language",
                      "Language name",
                      "Language name"
                    )}

                    <br />
                    {this.renderButton(`${btnLabel}`)}
                  </form>
                </span>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectLoadingStatus,
  Languages: selectAllLanguages,
  allCountries: selectAllCountries,
});

const mapDispatchToProps = (dispatch) => ({
  addLanguageAsync: (payload) => dispatch(addLanguageAsync(payload)),
  fetchLanguageAsync: () => dispatch(fetchLanguageAsync()),
  fetchCountryAsync: () => dispatch(fetchCountryAsync()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLanguages);
