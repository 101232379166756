import React, { Component } from "react";
import UsersTable from "../../components/usersTable";

class Users extends Component {
  render() {
    return (
      <React.Fragment>
        <h1>Admin Users Table</h1>
        <hr />
        <br />
        <UsersTable />
      </React.Fragment>
    );
  }
}

export default Users;
