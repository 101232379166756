import React, { Component } from "react";

import {
  selectAllMovies,
  selectOrders,
  selectOrderTotalPrice,
} from "../../redux/movies/movies.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectAllUsers } from "../../redux/users/users.selector";
import UsersTable from "../../components/usersTable";
import { NavLink } from "react-router-dom";

class Dashboard2 extends Component {
  state = {};

  render() {
    const {
      selectAllMovies,
      selectAllUsers,
      selectOrders,
      selectOrderTotalPrice,
    } = this.props;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-xl-12 col-xxl-12 d-flex">
            <div className="w-100">
              <div className="row">
                <div className="col-6 col-md-3">
                  <NavLink className="no-decoration" to="/movies-chart">
                    <div className="card bg-dark-purple text-white">
                      <div className="card-body">
                        <h5 className="card-title mb-4 text-white-50">
                          Movies
                        </h5>
                        <h1 className="mt-1 mb-3 font-weight-bolder text-white">
                          {selectAllMovies.length}
                        </h1>
                        <div className="mb-1">
                          <span className="text-danger">
                            {" "}
                            <i className="mdi mdi-arrow-bottom-right"></i>{" "}
                            -3.65%{" "}
                          </span>
                          <span className="text-muted">Since last week</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <div className="col-6 col-md-3">
                  <NavLink className="no-decoration" to="/users-chart">
                    <div className="card bg-dark-purple">
                      <div className="card-body">
                        <h5 className="card-title mb-4 text-white-50">Users</h5>
                        <h1 className="mt-1 mb-3 font-weight-bolder text-white">
                          {selectAllUsers.length}
                        </h1>
                        <div className="mb-1">
                          <span className="text-danger">
                            {" "}
                            <i className="mdi mdi-arrow-bottom-right"></i>{" "}
                            -3.65%{" "}
                          </span>
                          <span className="text-muted">Since last week</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <div className="col-6 col-md-3">
                  <NavLink className="no-decoration" to="/orders-chart">
                    <div className="card bg-dark-purple">
                      <div className="card-body">
                        <h5 className="card-title mb-4 text-white-50">
                          Orders
                        </h5>
                        <h1 className="mt-1 mb-3 font-weight-bolder text-white">
                          {selectOrders.length}
                        </h1>
                        <div className="mb-1">
                          <span className="text-success">
                            {" "}
                            <i className="mdi mdi-arrow-bottom-right"></i> 6.65%{" "}
                          </span>
                          <span className="text-muted">Since last week</span>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                </div>
                <div className="col-6 col-md-3">
                  <a className="no-decoration" href="/">
                    <div className="card bg-dark-purple">
                      <div className="card-body">
                        <h5 className="card-title mb-4 text-white-50">
                          Balance
                        </h5>
                        <h1 className="mt-1 mb-3 font-weight-bolder text-white">
                          ${selectOrderTotalPrice}
                        </h1>
                        <div className="mb-1">
                          <span className="text-danger">
                            {" "}
                            <i className="mdi mdi-arrow-bottom-right"></i>{" "}
                            -2.25%{" "}
                          </span>
                          <span className="text-muted">Since last week</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UsersTable />
        <br />
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  selectOrderTotalPrice: selectOrderTotalPrice,
  selectAllMovies: selectAllMovies,
  selectOrders: selectOrders,
  selectAllUsers: selectAllUsers,
});

export default connect(mapStateToProps)(Dashboard2);
