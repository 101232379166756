import React, { Component } from "react";
//import logo from "../assets/images/logo.svg";
import { FiHelpCircle, FiSettings } from "react-icons/fi";
import { RiLockPasswordLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import { getCurrentUser } from "../services/authServices";

class Navbar extends Component {
  state = {
    toggleNavbar: false,
  };

  toggle = () => {
    this.setState({ toggleNavbar: !this.state.toggleNavbar });
  };

  render() {
    return (
      <React.Fragment>
        {/* <!-- Top nav --> */}

        <NavLink className="navbar-brand py-0 d-block d-lg-none" to="/">
          {/* <img src={logo} width="150" alt="" /> */}
          <h3>African Movies</h3>
        </NavLink>

        <div className="navbar-collapse collapse">
          <ul className="navbar-nav navbar-align">
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link"
                href="/"
                id="messagesDropdown"
                data-toggle="dropdown"
              >
                <span>Help Guide</span>
              </a>
            </li> */}
            <li className="nav-item dropdown">
              <a
                className="nav-icon dropdown-toggle d-inline-block d-sm-none"
                data-toggle="dropdown"
                onClick={this.toggle}
              >
                <span className="">
                  <FiSettings />
                </span>
              </a>

              <a
                className="nav-link dropdown-toggle d-none d-sm-inline-block"
                data-toggle="dropdown"
                onClick={this.toggle}
              >
                {/* <!-- <img src="assets/dashboard/img/avatars/avatar.jpg" className="avatar img-fluid rounded mr-1" alt="Charles Hall" />  --> */}
                <span className="">
                  {getCurrentUser().username} ({getCurrentUser().role})
                </span>
              </a>
              <div
                className={
                  this.state.toggleNavbar
                    ? "dropdown-menu dropdown-menu-right show"
                    : "dropdown-menu dropdown-menu-right"
                }
                style={{ backgroundColor: "#0C0E17" }}
              >
                {/* <a className="dropdown-item" href="pages-profile.html">
                  <i className="align-middle mr-1" data-feather="user">
                    <FiUser />
                  </i>{" "}
                  Profile
                </a> */}
                <NavLink
                  className="dropdown-item"
                  to="/reset-password"
                  onClick={this.toggle}
                >
                  <i className="align-middle mr-1" data-feather="pie-chart">
                    <RiLockPasswordLine />
                  </i>{" "}
                  Change My Password
                </NavLink>
                <div
                  className="dropdown-divider"
                  style={{ backgroundColor: "#0F9ED1" }}
                ></div>
                <NavLink
                  className="dropdown-item"
                  to="/settings"
                  onClick={this.toggle}
                >
                  <i className="align-middle mr-1" data-feather="settings">
                    <FiSettings />
                  </i>{" "}
                  Settings (Movie Banner)
                </NavLink>
                <a className="dropdown-item" href="/">
                  <i className="align-middle mr-1" data-feather="help-circle">
                    <FiHelpCircle />
                  </i>{" "}
                  Help Guide
                </a>
                <div
                  className="dropdown-divider"
                  style={{ backgroundColor: "#0F9ED1" }}
                ></div>
                <NavLink className="dropdown-item" to="/logout">
                  Log out
                </NavLink>
              </div>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default Navbar;
