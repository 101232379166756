import usersActionTypes from "./users.types";
import { searchUser, deleteUser } from "./users.utils";

const INITIAL_STATE = {
  users: [],
  vertualUsers: [],
  allUsers: [],
  vertualAllUsers: [],
  isLoading: false,
};

const usersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case usersActionTypes.USERS_FETCH_START:
    case usersActionTypes.ALL_USERS_FETCH_START:
    case usersActionTypes.LOGIN_USER_START:
    case usersActionTypes.LOGIN_ADMIN_USER_START:
    case usersActionTypes.REGISTER_USER_START:
    case usersActionTypes.LOGIN_ADMIN_USER_SUCCESS:
    case usersActionTypes.ADD_ADMIN_START:
    case usersActionTypes.PASSWORD_RESET_START:
    case usersActionTypes.DELETE_USER_START:
      return {
        ...state,
        isLoading: true,
      };
    case usersActionTypes.USERS_FETCH_SUCCESS:
      return {
        ...state,
        users: action.payload,
        vertualUsers: action.payload,
        isLoading: false,
      };
    case usersActionTypes.ALL_USERS_FETCH_SUCCESS:
      return {
        ...state,
        vertualAllUsers: action.payload,
        allUsers: action.payload,
        isLoading: false,
      };
    case usersActionTypes.SEARCH_USER:
      return {
        ...state,
        vertualAllUsers: searchUser(state.allUsers, action.payload),
      };
    case usersActionTypes.DELETE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vertualUsers: deleteUser(state.vertualUsers, action.payload),
        vertualAllUsers: deleteUser(state.vertualAllUsers, action.payload),
      };
    case usersActionTypes.ALL_USERS_FETCH_FAILURE:
    case usersActionTypes.USERS_FETCH_FAILURE:
    case usersActionTypes.REGISTER_USER_SUCCESS:
    case usersActionTypes.REGISTER_USER_FAILURE:
    case usersActionTypes.ADD_ADMIN_SUCCESS:
    case usersActionTypes.ADD_ADMIN_FAILURE:
    case usersActionTypes.LOGIN_USER_SUCCESS:
    case usersActionTypes.LOGIN_USER_FAILURE:
    case usersActionTypes.LOGIN_ADMIN_USER_FAILURE:
    case usersActionTypes.PASSWORD_RESET_SUCCESS:
    case usersActionTypes.PASSWORD_RESET_FAILURE:
    case usersActionTypes.DELETE_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default usersReducer;
