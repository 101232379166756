import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";

const MovieSpinner = ({ trailerCompleted, videoCompleted }) => {
  return (
    <React.Fragment>
      <div style={{ marginTop: "80px" }}>
        <ProgressBar completed={trailerCompleted} bgColor="#120403" />;
        <span style={{ color: "grey" }}>Trailer video upload progress</span>
      </div>
      <div style={{ marginTop: "50px" }}>
        <ProgressBar completed={videoCompleted} bgColor="#0F9ED1" />;
        <span style={{ color: "grey" }}>Movie video upload progress</span>
      </div>
    </React.Fragment>
  );
};

export default MovieSpinner;
