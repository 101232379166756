import React, { useState } from "react";
import { addMovieAsync } from "../../redux/movies/movies.action";
import { selectLoadingStatus } from "../../redux/movies/movies.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { toast } from "react-toastify";
import WithSpinner from "../withSpinner";

const AddToAF = ({ movie, label, addMovieAsync, isLoading }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMovieAdd = async () => {
    setOpen(false);
    addMovieAsync({
      title: movie.title,
      price: movie.price,
      rating: movie.rating,
      description: movie.description,
      actor: movie.actor,
      genre: movie.genre,
      countryName: movie.countryName,
      language: movie.language,
      duration: movie.duration,
      isBanner: false,
      releaseType: movie.releaseType,
      releaseYear: movie.releaseYear,
      videoId: movie.videoId,
      movieVideoURL: movie.movieVideoURL,
      movieTrailerURL: movie.movieTrailerURL,
      moviePictureURL: movie.moviePictureURL,
      movieBannerPictureURL: movie.movieBannerPictureURL,
    });
    toast.success("Movie successfully added to African Movies.");
  };

  return isLoading ? (
    <WithSpinner />
  ) : (
    <React.Fragment>
      <label
        style={{ cursor: "pointer" }}
        className="label label-primary add-to-cart"
        onClick={handleClickOpen}
      >
        <span className="badge bg-primary btnLabel"> {label}</span>
      </label>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"African Movies Admin Dashboard."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Clicking on add movie will import this movie from african movies and
            add it to African Movies
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Dismiss
          </Button>
          <Button onClick={handleMovieAdd} color="primary" autoFocus>
            Add Movie
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  isLoading: selectLoadingStatus,
});

const mapDispatchToProps = (dispatch) => ({
  addMovieAsync: (payload) => dispatch(addMovieAsync(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddToAF);
