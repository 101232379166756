import React, { useState } from "react";
import DatePicker from "react-date-picker";
import moment from "moment";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { getMoviesCount, getReportMovies } from "../../utils/func";
import { selectVertualMovies } from "../../redux/movies/movies.selector";
import { selectUsers } from "../../redux/users/users.selector";

const UploadReports = ({ users, movies }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const newMovies = getReportMovies(movies, startDate, endDate);
  const [allMovies, setAllMovies] = useState(newMovies);

  const handleReport = () => setAllMovies(newMovies);
  return (
    <React.Fragment>
      <div className="row">
        <div style={{ float: "left", width: "33.33%" }}>
          <span style={{ color: "black" }}>Start Date : </span>
          <DatePicker
            value={startDate}
            onChange={(date) => setStartDate(date)}
          />
        </div>
        <div style={{ float: "left", width: "33.33%" }}>
          <span style={{ color: "black" }}>End Date : </span>
          <DatePicker value={endDate} onChange={(date) => setEndDate(date)} />
        </div>
        <div style={{ float: "left", width: "33.33%" }}>
          <button
            onClick={handleReport}
            style={{ backgroundColor: "#24BAEF" }}
            className={`btn btn-primary btn-lg w-100`}
          >
            Refresh Reports
          </button>
        </div>
      </div>
      <br />
      <br />
      <div className="widget-container">
        <div className=" widget-block">
          {users.length > 0 ? (
            <table className="table dt-table">
              <thead>
                <tr>
                  <th>
                    <b>Admin User</b>
                  </th>
                  <th>
                    <b>Email</b>
                  </th>
                  <th>
                    <b>Role</b>
                  </th>
                  <th>
                    <b>Registration Date</b>
                  </th>
                  <th>
                    <b>Movie Uploaded</b>
                  </th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>
                    <b>
                      Total user :{" "}
                      <span style={{ color: "green" }}>{users.length}</span>
                    </b>
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    <strong>
                      Total Uploaded :{" "}
                      <span style={{ color: "green" }}>{allMovies.length}</span>
                    </strong>
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </tfoot>
              <tbody>
                {users.map((user) => (
                  <tr key={user._id}>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>
                      {moment(user.registrationDate).format(
                        "dddd, MMMM Do YYYY"
                      )}
                    </td>
                    <td>
                      <span style={{ color: "green" }}>
                        {getMoviesCount(allMovies, user.email)}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h2>No records was found.</h2>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = createStructuredSelector({
  users: selectUsers,
  movies: selectVertualMovies,
});

export default connect(mapStateToProps)(UploadReports);
