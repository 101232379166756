import React, { Component } from "react";
import {
  getSuperAdminAccess,
  postSuperAdminAccess,
} from "../../services/usersService";
import WithSpinner from "../../components/withSpinner";

class SuperAdminAccess extends Component {
  state = {
    data: {},
    isLoading: false,
  };

  async componentDidMount() {
    const { data } = await getSuperAdminAccess();
    this.setState({
      data: {
        addMovie: data.addMovie,
        updateMovie: data.updateMovie,
        addCustomer: data.addCustomer,
        updateCustomer: data.updateCustomer,
        addAdmin: data.addAdmin,
        updateAdmin: data.updateAdmin,
        addGenre: data.addGenre,
        updateGenre: data.updateGenre,
      },
    });
  }

  toggleCheck = async (param) => {
    this.setState({ isLoading: true });
    await postSuperAdminAccess({
      ...this.state.data,
      [param]: !this.state.data[param],
    });
    window.location = "/super-admin-access";
  };

  render() {
    const {
      addMovie,
      updateMovie,
      addCustomer,
      updateCustomer,
      addAdmin,
      updateAdmin,
      addGenre,
      updateGenre,
    } = this.state.data;
    return this.state.isLoading ? (
      <WithSpinner />
    ) : (
      <React.Fragment>
        {/* <div style={{ maxWidth: "200px" }}> */}
        <h1>
          <u>Super Admin level access settings</u>
        </h1>
        <div className="form-group mt-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="terms"
              defaultChecked={addMovie}
              onClick={() => this.toggleCheck("addMovie")}
            />
            <label
              className="form-check-label small"
              htmlFor="terms"
              style={{ color: "black", fontSize: "16px" }}
            >
              Add Movie Management
            </label>
          </div>
        </div>
        <br />
        <div className="form-group mt-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="terms"
              defaultChecked={updateMovie}
              onClick={() => this.toggleCheck("updateMovie")}
            />
            <label
              className="form-check-label small"
              htmlFor="terms"
              style={{ color: "black", fontSize: "16px" }}
            >
              Update and delete movie
            </label>
          </div>
        </div>
        <br />
        <div className="form-group mt-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="terms"
              defaultChecked={addCustomer}
              onClick={() => this.toggleCheck("addCustomer")}
            />
            <label
              className="form-check-label small"
              htmlFor="terms"
              style={{ color: "black", fontSize: "16px" }}
            >
              Add Customer Management
            </label>
          </div>
        </div>
        <br />
        <div className="form-group mt-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="terms"
              defaultChecked={updateCustomer}
              onClick={() => this.toggleCheck("updateCustomer")}
            />
            <label
              className="form-check-label small"
              htmlFor="terms"
              style={{ color: "black", fontSize: "16px" }}
            >
              Update and delete customer
            </label>
          </div>
        </div>
        <br />
        <div className="form-group mt-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="terms"
              defaultChecked={addGenre}
              onClick={() => this.toggleCheck("addGenre")}
            />
            <label
              className="form-check-label small"
              htmlFor="terms"
              style={{ color: "black", fontSize: "16px" }}
            >
              Add genre management
            </label>
          </div>
        </div>
        <br />
        <div className="form-group mt-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="terms"
              defaultChecked={updateGenre}
              onClick={() => this.toggleCheck("updateGenre")}
            />
            <label
              className="form-check-label small"
              htmlFor="terms"
              style={{ color: "black", fontSize: "16px" }}
            >
              Update and delete genre
            </label>
          </div>
        </div>
        <br />
        <div className="form-group mt-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="terms"
              defaultChecked={addAdmin}
              onClick={() => this.toggleCheck("addAdmin")}
            />
            <label
              className="form-check-label small"
              htmlFor="terms"
              style={{ color: "black", fontSize: "16px" }}
            >
              Add admin management
            </label>
          </div>
        </div>
        <br />
        <div className="form-group mt-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="terms"
              defaultChecked={updateAdmin}
              onClick={() => this.toggleCheck("updateAdmin")}
            />
            <label
              className="form-check-label small"
              htmlFor="terms"
              style={{ color: "black", fontSize: "16px" }}
            >
              Update and delete admin
            </label>
          </div>
        </div>
        {/* </div> */}
      </React.Fragment>
    );
  }
}

export default SuperAdminAccess;
