import genresActionType from "./genres.type";
import { toast } from "react-toastify";
import {
  getGenres,
  postGenre,
  deleteGenre,
} from "../../services/genresServices";

export const fetchGenresStart = () => ({
  type: genresActionType.FETCH_GENRE_START,
});

export const fetchGenresSuccess = (genres) => ({
  type: genresActionType.FETCH_GENRE_SUCCESS,
  payload: genres,
});

export const fetchGenresFailure = () => ({
  type: genresActionType.FETCH_GENRE_FAILURE,
});

export const fetchGenresAsync = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchGenresStart());
      const { data } = await getGenres();
      dispatch(fetchGenresSuccess(data));
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        dispatch(fetchGenresFailure());
        toast.error(ex.response.data);
      }
    }
  };
};

export const addGenreStart = () => ({
  type: genresActionType.ADD_GENRE_START,
});

export const addGenreSuccess = () => ({
  type: genresActionType.ADD_GENRE_SUCCESS,
});

export const addGenreFailure = () => ({
  type: genresActionType.ADD_GENRE_FAILURE,
});

export const addGenreAsync = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(addGenreStart());
      await postGenre(payload);
      dispatch(addGenreSuccess());
      toast.success("Genre successfully saved");
      setTimeout(() => (window.location = "/add-genre/new"), 3000);
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        dispatch(addGenreFailure());
        toast.error(ex.response.data);
        setTimeout(() => (window.location = "/add-genre/new"), 3000);
      }
    }
  };
};

export const deleteGenreStart = () => ({
  type: genresActionType.DELETE_GENRE_START,
});

export const deleteGenreSuccess = (genreId) => ({
  type: genresActionType.DELETE_GENRE_SUCCESS,
  payload: genreId,
});

export const deleteGenreFailure = () => ({
  type: genresActionType.DELETE_GENRE_FAILURE,
});

export const deleteGenreAsync = (genreId) => {
  return async (dispatch) => {
    try {
      dispatch(deleteGenreStart());
      await deleteGenre(genreId);
      dispatch(deleteGenreSuccess(genreId));
      toast.success("Genre successfully deleted");
    } catch (ex) {
      if (ex.response && ex.response.status === 404) {
        dispatch(deleteGenreFailure());
        toast.error(ex.response.data);
      }
    }
  };
};
