import React, { Component } from "react";
import { selectOrders } from "../redux/movies/movies.selector";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Table from "./tableComponent/table";
import { searchOrder } from "../redux/movies/movies.action";
// import TableUpdateButton from "./tableComponent/tableUpdateButton";
// import TableDeleteButton from "./tableComponent/tableDeleteButton";

class OrdersTable extends Component {
  columns = [
    { title: "Customer", path: "username" },
    { title: "Movie Title", path: "title" },
    { title: "Paid Amount", path: "price" },
    { title: "Actor", path: "actor" },
    { title: "Movie Longevity (Days)", path: "longevity" },
    // {
    //   title: "Update",
    //   buttons: (genre) => <TableUpdateButton genre={genre} label="Update" />,
    //   className: "tc-center",
    // },
    // {
    //   title: "Delete Genre",
    //   buttons: (genre) => <TableDeleteButton genre={genre} label="Delete" />,
    //   className: "tc-center",
    // },
  ];
  render() {
    const { orders, searchOrder } = this.props;
    return (
      <React.Fragment>
        <Table
          elements={orders}
          columns={this.columns}
          search={searchOrder}
          title="All Orders"
          show="search"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  orders: selectOrders,
});

const mapDispatchToProps = (dispatch) => ({
  searchOrder: (searchWord) => dispatch(searchOrder(searchWord)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrdersTable);
