import React, { Component } from "react";

class Footer extends Component {
  state = {};
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container-fluid">
            <div className="row text-muted">
              <div className="col-6 text-left">
                <p className="mb-0">
                  <a href="index.html" className="text-muted">
                    <strong>African Movies</strong>
                  </a>
                  {"  "}
                  &copy; {new Date().getFullYear()}
                </p>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
