import React, { Component } from "react";

class TableHeader extends Component {
  state = {};
  render() {
    const { columns } = this.props;
    return (
      <React.Fragment>
        <thead>
          <tr>
            {columns.map((column) => (
              <th className="d-none d-md-table-cell" key={column.title}>
                {column.title}
              </th>
            ))}
          </tr>
        </thead>
      </React.Fragment>
    );
  }
}

export default TableHeader;
