import React, { Component } from "react";
import SideBar from "../components/sidebar";
import Navbar from "../components/navbar";
import { Route } from "react-router";
import { Redirect } from "react-router-dom";
import Dashboard from "./pagesContainer/dashboard";
import Users from "./pagesContainer/users";
import AddUser from "./pagesContainer/addUsers";
import Uptic from "./pagesContainer/uptic";
import Footer from "../components/footer";
import Dashboard2 from "./pagesContainer/dashboard2";
import Settings from "./pagesContainer/settings";

//import Login from "./login";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  fetchMoviesAsync,
  fetchOrderAsync,
} from "../redux/movies/movies.action";
import { fetchGenresAsync } from "../redux/moviesGenre/genres.action";
import {
  fetchCountryAsync,
  fetchLanguageAsync,
} from "../redux/country/country.action";
import { selectLoadingStatus } from "../redux/movies/movies.selector";
import { createStructuredSelector } from "reselect";
import {
  fetchUsersAsync,
  fetchAllUsersAsync,
} from "../redux/users/users.action";
import WithSpinner from "../components/withSpinner";
import { ToastContainer } from "react-toastify";
import AddGenre from "./pagesContainer/addGenre";
import AllGenres from "./pagesContainer/allGenres";
import AddMovie from "./pagesContainer/addMovie";
import AllMovies from "./pagesContainer/allMovies";
import Logout from "./pagesContainer/logout";
import ResetPassword from "./pagesContainer/resetPassword";
import AllOrders from "./pagesContainer/allOrders";
import Customers from "./pagesContainer/customers";
import AddCustomer from "./pagesContainer/addCustomer";
import Statistics from "./pagesContainer/statistics";
import AddMovieWithFile from "./pagesContainer/addMovieWithFile";
import UsersChart from "./pagesContainer/usersChart";
import MoviesChart from "./pagesContainer/moviesChart";
import OrdersChart from "./pagesContainer/ordersChart";
import NotFound from "./pagesContainer/notFound";
import UpdateMovie from "./pagesContainer/updateMovie";
import UpdateMovieFiles from "./pagesContainer/updateMovieFiles";
import AddMovieBanner from "./pagesContainer/addMovieBanner";
import AdminAccess from "./pagesContainer/adminAccess";
import SuperAdminAccess from "./pagesContainer/superAdminAccess";

import "../stylesheets/style.css";
import "../stylesheets/app.css";
import "../stylesheets/dashboard.css";
import "../stylesheets/admin.css";
import AddCountry from "./pagesContainer/addCountry";
import AllCountries from "./pagesContainer/allCountries";
import AddLanguages from "./pagesContainer/addLanguages";
import AllLanguages from "./pagesContainer/allLanguages";
import UploadReports from "./pagesContainer/uploadReports";
import MoviesSync from "./pagesContainer/moviesSync";
import WebQrcode from "./pagesContainer/webQrcode";
import AppQrcode from "./pagesContainer/appQrcode";

class WrapperPages extends Component {
  state = {
    sidebarToggled: false,
  };

  toggleSidebar = () => {
    this.setState({ sidebarToggled: !this.state.sidebarToggled });
  };

  componentDidMount() {
    const {
      fetchUsersAsync,
      fetchMoviesAsync,
      fetchOrderAsync,
      fetchAllUsersAsync,
      fetchGenresAsync,
      fetchCountryAsync,
      fetchLanguageAsync,
    } = this.props;
    fetchUsersAsync();
    fetchMoviesAsync();
    fetchOrderAsync();
    fetchAllUsersAsync();
    fetchGenresAsync();
    fetchCountryAsync();
    fetchLanguageAsync();
  }

  render() {
    const { isLoading, match } = this.props;
    return isLoading ? (
      <WithSpinner />
    ) : (
      <React.Fragment>
        <div className="wrapper">
          <nav
            id="sidebar"
            className={
              this.state.sidebarToggled ? "sidebar collapsed" : "sidebar"
            }
            // style={{ position: "fixed", top: "0" }}
          >
            <SideBar toggleSidebar={this.toggleSidebar} />
          </nav>
          <div className="main">
            <nav className="navbar main-nav navbar-expand navbar-dark navbar-bg fixed-top">
              <a
                className="sidebar-toggle d-flex d-lg-none"
                onClick={this.toggleSidebar}
              >
                <i className="hamburger align-self-center"></i>
              </a>
              <Navbar />
            </nav>
            <main className="content">
              <div className="container-fluid p-0">
                <Route path="/" component={Dashboard} />
                <Route path="/users" component={Users} />
                <Route path="/customers" component={Customers} />
                <Route path="/upload-reports" component={UploadReports} />
                <Route path="/users-chart" component={UsersChart} />
                <Route path="/movies-chart" component={MoviesChart} />
                <Route path="/orders-chart" component={OrdersChart} />
                <Route path="/add-customer/:id" component={AddCustomer} />
                <Route path="/add-user/:id" component={AddUser} />
                <Route path="/dashboard" component={Dashboard2} />
                <Route path="/reset-password" component={ResetPassword} />
                <Route path="/uptic" component={Uptic} />
                <Route path="/settings" component={Settings} />
                <Route path="/add-genre/:id" component={AddGenre} />
                <Route path="/statistics" component={Statistics} />
                <Route path="/all-movies" component={AllMovies} />
                <Route path="/all-orders" component={AllOrders} />
                <Route path="/add-movie" component={AddMovie} />
                <Route path="/admin-access" component={AdminAccess} />
                <Route path="/add-country/:id" component={AddCountry} />
                <Route path="/all-countries" component={AllCountries} />
                <Route path="/add-language/:id" component={AddLanguages} />
                <Route path="/all-languages" component={AllLanguages} />
                <Route path="/webqrcode" component={WebQrcode} />
                <Route path="/appqrcode" component={AppQrcode} />
                <Route
                  path="/super-admin-access"
                  component={SuperAdminAccess}
                />
                <Route path="/add-movie-banner" component={AddMovieBanner} />
                <Route path="/add-movie-file" component={AddMovieWithFile} />
                <Route
                  path="/update-movie-details/:id"
                  component={UpdateMovie}
                />
                <Route
                  path="/update-movie-files/:id"
                  component={UpdateMovieFiles}
                />
                <Route path="/movies-sync" component={MoviesSync} />
                <Route path="/all-genres" component={AllGenres} />
                <Route path="/logout" component={Logout} />
                {match.isExact && <Redirect from="/" to="/dashboard" />}
                <Route path="/not-found" component={NotFound} />
                {/* <Redirect to="/not-found" /> */}
              </div>
            </main>
            <Footer />
          </div>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchUsersAsync: () => dispatch(fetchUsersAsync()),
  fetchAllUsersAsync: () => dispatch(fetchAllUsersAsync()),
  fetchMoviesAsync: () => dispatch(fetchMoviesAsync()),
  fetchOrderAsync: () => dispatch(fetchOrderAsync()),
  fetchGenresAsync: () => dispatch(fetchGenresAsync()),
  fetchCountryAsync: () => dispatch(fetchCountryAsync()),
  fetchLanguageAsync: () => dispatch(fetchLanguageAsync()),
});

const mapStateToProps = createStructuredSelector({
  isLoading: selectLoadingStatus,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  WrapperPages
);
